<template>
  <v-row style="height: 100%" no-gutters>
    <v-col
      cols="12"
      class="pa-0"
      style="width: 100%; position: relative"
      :id="id"
    >
      <input
        @input="$emit('input', $attrs.value)"
        type="range"
        class="verticalBar"
        readonly
        v-model="$attrs.value"
        :min="min"
        :max="max"
        :style="
          `width: ${height};
          height: 60px; 
          position:absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%) rotate(-90deg);
          background-image: linear-gradient(to right, ${frontColor} 0%, ${frontColor} ` +
          backgroundWidth +
          `%, ${backColor} ` +
          backgroundWidth +
          '% );'
        "
      />
    </v-col>
  </v-row>
</template>
<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "InputRange",
  data() {
    return {
      id: uuidv4(),
      height: "100%",
      interval: null,
    };
  },
  mounted() {
    this.interval = setInterval(() => this.getHeight(), 50);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    getHeight() {
      const element = document.getElementById(this.id);
      console.log("height:D");
      if (element == null) return;

      const style = window.getComputedStyle(element);

      const heightWithoutPadding =
        element.clientHeight -
        parseFloat(style.paddingTop) -
        parseFloat(style.paddingBottom);

      const newHeight = heightWithoutPadding * 0.9 + "px";
      if (newHeight != this.height) this.height = newHeight;
    },
  },
  props: {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    label: { type: String, default: "" },
    frontColor: { type: String, default: "var(--v-primary-base)" },
    backColor: { type: String, default: "var(--v-background-base)" },
  },

  computed: {
    backgroundWidth() {
      return (this.$attrs.value / (this.$props.max - this.$props.min)) * 100;
    },
  },
};
</script>
<style>
.verticalBar[type="range"] {
  appearance: none;
  height: 40px;
  border-radius: 2rem;
  overflow: hidden;
}

.verticalBar[type="range"]::-webkit-slider-thumb {
  display: none;
  appearance: none;
}
</style>
