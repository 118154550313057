<template>
  <div
    class="fill-height d-flex align-center justify-center flex-column"
    v-if="error.error"
  >
    <v-icon class="mb-2" color="primary lighten-1" size="60px">{{
      chartIcon
    }}</v-icon>
    <div class="text-h6" style="font-size: 0.95rem !important">
      <span>
        {{ $t(error.message) }}
      </span>
    </div>
  </div>
  <div
    v-else-if="loading"
    class="d-flex fill-height justify-center align-center"
  >
    <Loader :size="120"></Loader>
  </div>
  <div
    class="fill-height"
    style="position: relative"
    v-else-if="!error.error && !loading"
  >
    <VerticalBar
      v-if="$props.chart.type === 'VerticalBar'"
      :min="chart.configs.min()"
      :max="chart.configs.max()"
      :value="chart.stats[0][chart.configs.frontMetric]"
      label=""
      :frontColor="chart.colors[chart.configs.frontMetric]"
      :backColor="
        chart.colors[
          Object.keys(chart.colors).find(k => k != chart.configs.frontMetric)
        ]
      "
    />
    <BarChart
      ref="chart"
      :data="chartData"
      :options="chartOptionsLine"
      v-if="$props.chart.type === 'BarChart' && configs"
    />
    <LineChart
      ref="chart"
      :data="chartData"
      :options="chartOptionsLine"
      v-if="$props.chart.type === 'LineChart' && configs"
    />
    <PieChart
      ref="chart"
      :data="chartData"
      :options="chartOptionsCircle"
      v-if="$props.chart.type === 'PieChart' && configs"
    />
    <DoughnutChart
      ref="chart"
      :data="chartData"
      :options="chartOptionsCircle"
      v-if="$props.chart.type === 'DoughnutChart' && configs"
    />
    <TotalChart
      ref="chart"
      :data="chartData"
      :chart="$props.chart"
      v-if="$props.chart.type === 'TotalChart' && configs"
    />
    <PagedTable
      ref="chart"
      :headers="tableData.headers"
      :data="tableData.data"
      :pagination="pagination"
      maxHeight="calc(100% - 24px)"
      :translate="false"
      useTooltip
      @changeItemsPerPage="changeItemsPerPage"
      @previousPage="previousPage"
      @nextPage="nextPage"
      v-if="$props.chart.type === 'DataTable'"
    />
    <SankeyChart
      ref="chart"
      :data="chartData"
      :options="chartOptionsSankey"
      v-if="$props.chart.type === 'SankeyChart' && configs"
    />
  </div>
</template>

<script>
import {
  luxon,
  useWallboardStore,
  channels,
  colorPerTheme,
  colorOfVar,
} from "@/utils";
import BarChart from "@/components/wallboards/charts/BarChart";
import LineChart from "@/components/wallboards/charts/LineChart";
import PieChart from "@/components/wallboards/charts/PieChart";
import DoughnutChart from "@/components/wallboards/charts/DoughnutChart";
import TotalChart from "@/components/wallboards/charts/TotalChart";
import PagedTable from "@/components/base/PagedTable";
import SankeyChart from "@/components/wallboards/charts/SankeyChart";
import VerticalBar from "@/components/wallboards/charts/VerticalBar";

import Stats from "@/controllers/stats";
import { zoom, hoverLine } from "./charts/plugins";

export default {
  name: "AutomaticChart",

  components: {
    BarChart,
    LineChart,
    PieChart,
    DoughnutChart,
    TotalChart,
    PagedTable,
    SankeyChart,
    VerticalBar,
  },

  props: {
    wallboardName: { type: String },
    chart: { type: Object },
    stats: { type: Array },
    sources: { type: Array },
    globalSearch: { type: Object },
    dataFilters: { type: Object },
    viewOnly: { type: Boolean },
    externalData: { type: Array },
    customStats: { type: Object },
    customSearch: { type: Object },
    dimensions: { type: Object },
    statuses: { type: Array, default: () => [] },
  },

  data() {
    return {
      metrics: [],
      filters: {},
      pagination: {
        itemsPerPage: 5,
        page: 1,
        totalRecords: 0,
        noPagination: true,
      },
      data: [],
      maxY: 0,
      loading: false,
      configs: null,
      error: {},
      luxon,
      channels,
      colorPerTheme,
    };
  },

  async mounted() {
    this.error = this.handleError();
    if (!this.error.error) {
      await this.getRecords();
      this.$emit("success");
    } else this.$emit("error");
    this.configs = this.handleConfig();
  },

  computed: {
    totalMetrics() {
      let metrics = [];
      if (
        this.$props.chart.metrics &&
        this.$props.chart.metrics.metrics &&
        this.$props.chart.metrics.metrics.length
      ) {
        metrics = this.$props.chart.metrics.metrics.map(e => e.id);
      }
      if (this.$props.chart.customMetrics) {
        metrics = metrics.concat(
          this.$props.chart.customMetrics.metrics.map(e => e.id)
        );
      }

      return metrics;
    },

    chartOptionsCircle() {
      let config = this.getChartConfig();
      const configs = this.configs;
      let legend = {};
      let title = {};

      legend.display = config && config.legend && !configs.compareDates;
      title.display = config && config.legend && configs.compareDates;

      if (
        this.$props.chart.configs &&
        this.$props.chart.configs.legendPosition
      ) {
        legend.display = true;
        legend.position = this.$props.chart.configs.legendPosition;
      }
      if (this.$props.chart.configs && this.$props.chart.configs.hideLegend) {
        legend.display = !this.$props.chart.configs.hideLegend;
      }
      if (this.$props.dimensions) {
        const { width, height } = this.$props.dimensions;

        if (width > height) {
          legend.position = "right";
        }
      }
      legend.labels = {
        color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
      };

      if (title.display) {
        title.text = this.totalMetrics.map(e => this.getLabel(e)).join(", ");
      }

      let x = { display: false };

      legend.labels.boxWidth = 9;
      legend.labels.boxHeight = 9;
      legend.labels.usePointStyle = true;
      legend.labels.pointStyle = "circle";

      let centerText = false;

      if (this.$props.chart.configs.centerText) {
        let { text, color } = this.$props.chart.configs.centerText();
        centerText = {
          display: true,
          text: text,
          color: color || this.$vuetify.theme.currentTheme.primary,
        };
      }

      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: x,
        },
        datasets: {
          doughnut: {
            borderColor: colorPerTheme(),
          },
        },
        plugins: {
          legend: legend,
          title: title,
          hoverLine: false,
          datalabels: this.$props.chart.configs.percentageLabels
            ? {
                formatter: (value, context) => {
                  let dataset = context.chart.config._config.data.datasets[0];
                  let total = dataset
                    ? dataset.data.reduce((a, b) => a + b, 0)
                    : 0;

                  return Math.round((value / total) * 100) + "%";
                },
                color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
                labels: {
                  title: {
                    font: {
                      weight: "bold",
                    },
                  },
                },
              }
            : false,
          tooltip: {
            callbacks: {
              label: context => this.tooltipLabel(context),
            },
          },
          centerText,
        },
      };
    },
    chartOptionsLine() {
      let unit = "";

      if (this.$props.customSearch) {
        unit = this.$props.customSearch.interval;
      } else if (this.$props.globalSearch) {
        unit = this.$props.globalSearch.interval;
      }
      let timeScale = false;
      const configs = this.configs;
      if (
        this.data &&
        this.data.length &&
        (configs.xAxis === "date" || configs.compareDates)
      ) {
        timeScale = this.isValidDate(this.data[0][configs.xAxis] || "");
      }

      if (this.$props.customSearch) {
        unit = this.$props.customSearch.interval;
      } else if (timeScale) {
        unit = this.$props.globalSearch.interval;
      }

      let chartConfig = this.getChartConfig();
      let showLegend = chartConfig && chartConfig.legend;
      let legend = { display: showLegend, position: "top" };

      if (this.$props.chart.configs && this.$props.chart.configs.legendPosition)
        legend.position = this.$props.chart.configs.legendPosition;

      if (this.$props.chart.configs && this.$props.chart.configs.hideLegend) {
        legend.display = !this.$props.chart.configs.hideLegend;
      }

      legend.labels = {
        color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
      };

      legend.labels.boxWidth = 9;
      legend.labels.boxHeight = 9;
      legend.labels.usePointStyle = true;
      legend.labels.pointStyle = "circle";

      let title = { display: false };
      let x = {
        display: !this.$props.chart.configs.hideLabels,
        type: "category",
        ticks: {
          autoSkip: false,
          color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
        },
        grid: {
          display: false,
        },
      };
      if (timeScale) {
        let minUnit = {
          15: "minute",
          30: "minute",
          60: "hour",
          120: "hour",
          1440: "day",
          10080: "week",
          mth: "month",
          bim: "month",
          tri: "month",
          qtr: "month",
          sem: "month",
          yer: "year",
        };

        x = {
          display: true,
          type: "time",
          time: {
            displayFormats: {
              minute: "HH:mm:ss",
              hour: "T", //Example : 16:03
              day: "dd/MM/yyyy", //Example :  || D: 9/4/2015 (Localized)
              week: "ccc, D", //Example : Mon, 4
              month: "LLL yyyy", //Example : Sep 2015
              quarter: "[Q]q - yyyy", //Example : Q3 - 2015
              year: "yyyy", //Example : 2015
            },
            minUnit: minUnit[unit] || "day",
          },
          offsetAfterAutoskip: true,
          grid: {
            display: false,
          },
          stacked: this.$props.chart.configs.stacked,
          ticks: {
            source: "labels",

            color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
            autoSkip: true,
            autoSkipPadding: 20,
            maxRotation: 0,
            sampleSize: 100,
          },
        };
      }

      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ...x,
            stacked: this.$props.chart.configs.stacked,
            ticks: this.$props.chart.configs.showTimeXAxis
              ? {
                  source: "labels",
                  ...x.ticks,

                  callback: (value, index) => {
                    let res = [];
                    let label =
                      value > 100000
                        ? value
                        : index < 100000
                        ? this.totalMetrics[index]
                        : this.totalMetrics[0];

                    if (!this.$props.chart.configs.hideLabelText) {
                      res = [this.getLabel(label)];
                    }

                    if (this.$props.chart.configs.showTimeXAxis) {
                      res.push(
                        this.convertSecondsToTimeFormat(
                          this.$props.externalData[0][label]
                        )
                      );
                    }

                    return res;
                  },
                }
              : x.ticks,
          },
          y: {
            grid: {
              display: this.$props.chart.configs.hideYGrid !== true,
              color: this.$vuetify.theme.dark
                ? "rgba(255, 255, 255, 0.12)"
                : "rgba(0, 0, 0, 0.12)",
              borderColor: this.$vuetify.theme.dark
                ? "rgba(255, 255, 255, 0.12)"
                : "rgba(0, 0, 0, 0.12)",
            },
            ticks: {
              display: !this.$props.chart.configs.hideYAxis,
              color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
              callback: value => {
                return this.$props.chart.configs.convertTo == "time" ||
                  configs.convertTo == "time"
                  ? this.convertSecondsToTimeFormat(value)
                  : configs.convertTo == "percentage"
                  ? value + "%"
                  : value;
              },
            },
            border: {
              dash: [2, 2],
            },
            beginAtZero: true,
            max:
              this.$props.chart.configs.convertTo ||
              configs.convertTo == "percentage"
                ? 100
                : null,
            stacked: this.$props.chart.configs.stacked,
          },
        },
        interaction: {
          mode: this.$props.chart.configs.interactionMode
            ? this.$props.chart.configs.interactionMode
            : "nearest",
          intersect: false,
          axis: "x",
        },
        plugins: {
          zoom,
          legend,
          title,
          hoverLine,
          tooltip: {
            boxWidth: 9,
            boxHeight: 9,
            usePointStyle: true,
            pointStyle: "circle",
            titleAlign: "center",
            xAlign: "center",
            backgroundColor: colorPerTheme("accent"),
            titleColor: this.$vuetify.theme.dark ? "white" : "#505253",
            bodyColor: this.$vuetify.theme.dark ? "white" : "#505253",
            callbacks: {
              title: context => this.tooltipTitle(context),
              label: context => this.tooltipLabel(context),
            },
          },
          datalabels: this.$props.chart.configs.percentageLabels
            ? {
                formatter: value => {
                  return value + "%";
                },
                color: this.$vuetify.theme.dark ? "#C4C4C4" : "#545454",
                labels: {
                  title: {
                    font: {
                      weight: "bold",
                    },
                  },
                },
              }
            : null,
        },
        elements: {
          point: {
            borderWidth: 0,
            radius: 0.1,
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
        layout: {
          padding: {
            left: this.$props.chart.configs.layoutPaddingLeft
              ? this.$props.chart.configs.layoutPaddingLeft
              : null,
            right: this.$props.chart.configs.layoutPaddingRight
              ? this.$props.chart.configs.layoutPaddingRight
              : null,
          },
        },
        animation: this.$props.chart.configs.animation
          ? this.$props.chart.configs.animation
          : false,
      };
    },
    chartOptionsSankey() {
      return {
        parsing: {
          from: "parentId",
          to: "id",
          flow: "flow",
        },
        plugins: {
          hoverLine: false,
          tooltip: {
            callbacks: {
              label: function (context) {
                console.log(context);
                var label =
                  context.dataset.labels[context.raw.parentId] ||
                  context.raw.parentId;

                label +=
                  "➡️" + context.dataset.labels[context.raw.id] ||
                  context.raw.id;

                label += ": " + context.raw.flow;

                return label;
              },
            },
          },
        },
      };
    },
    chartData() {
      if (this.$props.chart.type === "SankeyChart") return this.sankeyData();
      if (this.configs.groupByCol) return this.byCols();
      else return this.byRows();
    },

    chartDataTotal() {
      return this.chartData;
    },

    chartIcon() {
      let type = this.$props.chart.type;
      if (type === "DataTable") return "mdi-table";
      if (type === "LineChart") return "mdi-chart-line";
      if (type === "BarChart") return "mdi-chart-bar";
      if (type === "PieChart") return "mdi-chart-pie";
      if (type === "DoughnutChart") return "mdi-chart-donut";
      if (type === "TotalChart") return "mdi-numeric";

      return "mdi-chart-line";
    },

    //Data table

    tableData() {
      let chartDataHeaders = this.nameCols.filter(
        e => e !== "datetimeInterval"
      );
      if (this.$props.chart.by !== "resource")
        chartDataHeaders = chartDataHeaders.filter(e => e !== "resource");

      if (this.$props.chart.source === "query")
        chartDataHeaders = chartDataHeaders.filter(
          e => !this.configs.excluded.includes(e)
        );

      let headers = chartDataHeaders.map(e => {
        return {
          text: this.getLabel(e),

          value: e,
          align: "center",
          sortable: true,
        };
      });

      let data = this.data.map(row => {
        let newRow = {};
        for (let key of chartDataHeaders) {
          switch (key) {
            case "channel":
              newRow[key] = this.channels.find(e => e.value === row[key]);
              break;
            case "date":
              if (this.isValidDate(row[key])) {
                newRow[key] = this.luxon()
                  .fromSQL(row[key])
                  .toLocaleString(luxon().DATETIME_MED_WITH_SECONDS);
              } else newRow[key] = row[key];
              break;
            case "resource":
              newRow[key] = row[key];
              break;
            default:
              newRow[key] =
                this.$props.chart.metrics.group.name === "Time"
                  ? this.convertSecondsToTimeFormat(row[key])
                  : row[key];
              break;
          }
        }

        return newRow;
      });

      this.setPaginationData(data);

      return {
        headers: headers,
        data: data,
      };
    },

    nameCols() {
      let cols = [];
      if (this.data && this.data.length)
        this.data.forEach(row => {
          Object.keys(row).forEach(key => {
            if (!cols.includes(key)) {
              cols.push(key);
            }
          });
        });

      this.$emit("nameCols", cols);

      return cols;
    },

    colsData() {
      let cols = {};
      const nameCols = this.nameCols;
      if (nameCols.length) {
        for (let col of nameCols) {
          cols[col] = this.data.map(c => c[col]);
        }
      }

      return cols;
    },

    rowsData() {
      let rows = {};
      const configs = this.configs;
      const nameRow = configs.col || "resource";
      for (let data of this.data) {
        if (!rows[data[nameRow]]) {
          rows[data[nameRow]] = [];
        }
        rows[data[nameRow]].push(data);
      }

      return rows;
    },
  },

  methods: {
    async getRecords() {
      this.loading = true;

      if (this.$props.externalData) {
        this.data = this.$props.externalData;
      }
      this.error = this.handleError();
      if (this.$props.externalData) {
        this.data = this.$props.externalData;
      } else if (this.$props.customStats) {
        this.data = await Stats.getStatsBySource(this.$props.customStats);
      } else {
        if (!this.error.error)
          if (this.$props.chart.source === "query") {
            this.data = await Stats.getStatsQuery(
              this.$props.chart,
              this.$props.globalSearch
            );
          } else {
            this.data = await Stats.getStats(
              this.$props.wallboardName,
              JSON.parse(JSON.stringify(this.$props.chart)),
              this.$props.globalSearch
            );
          }
      }
      this.$nextTick(() => {
        this.loading = false;
      });
    },

    getChartConfig() {
      if (this.$props.viewOnly) {
        return {
          legend: false,
        };
      }
      if (this.$props.customSearch) {
        return {
          legend: true,
        };
      }

      return useWallboardStore().getChart(
        this.$props.wallboardName,
        this.$props.chart.lid,
        "config"
      );
    },
    getChartFilters() {
      if (this.$props.viewOnly || this.$props.customSearch) {
        return {};
      }

      return (
        useWallboardStore().getChart(
          this.$props.wallboardName,
          this.$props.chart.lid,
          "filters"
        ) || {}
      );
    },

    handleError() {
      if (
        this.$props.viewOnly ||
        this.$props.customStats ||
        this.$props.externalData
      ) {
        return { error: false, message: "" };
      }

      const { chart, globalSearch } = this.$props;
      if (chart.source === "query" && (!chart.query || !chart.query.trim()))
        return { error: true, message: "No expression to show" };
      if (chart.source !== "query") {
        const metrics = this.totalMetrics;
        const source = `${chart.source}s`;
        let chartFilters = this.getChartFilters();
        if (JSON.stringify(chartFilters) === "{}") chartFilters = chart.filters;
        if (metrics.length === 0)
          return { error: true, message: "No selected metrics" };
        if (
          !(chartFilters[source] && chartFilters[source].length) &&
          !(globalSearch && globalSearch[source] && globalSearch[source].length)
        )
          return { error: true, message: "No " + source + " selected" };
        if (source === "campaigns") {
          if (
            !(chartFilters.channels && chartFilters.channels.length) &&
            !(globalSearch.channels && globalSearch.channels.length)
          )
            return { error: true, message: "No channels selected" };
        }
      }

      return { error: false, message: "" };
    },

    handleConfig() {
      const { type, by, configs, source, metrics, customMetrics } =
        this.$props.chart;
      if (source === "query") return configs;
      const { excluded, colorByCol, parent, child, flow } = configs || {};

      const fullGroup = [metrics?.group?.name, customMetrics?.group?.name];

      let convertTo = fullGroup.includes("Time")
        ? "time"
        : fullGroup.includes("Percentage")
        ? "percentage"
        : null;
      if (type === "LineChart" && by === "metric")
        return {
          xAxis: "date",
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useSecondFieldAsLabel: false,
          label: "date",
          byChannel: false,
          compareDates: false,
          groupByCol: false,
          col: "resource",
          convertTo,
        };

      if (type === "LineChart" && by !== "metric")
        return {
          xAxis: "date",
          excluded: ["datetimeInterval", "channel", "resource"].concat(
            excluded || []
          ),
          useAColAsAxis: true,
          label: "date",
          byChannel: by === "channel",
          compareDates: false,
          groupByCol: true,
          groupChannels: false,
          col: by,
          convertTo,
        };

      if (type === "BarChart" && by !== "metric")
        return {
          xAxis: by,
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useAColAsAxis: false,
          label: by,
          byChannel: by === "channel",
          compareDates: false,
          groupByCol: true,
          col: by,
          colorByCol: colorByCol || false,
          convertTo,
        };

      if (type === "BarChart" && by === "metric")
        return {
          xAxis: "resource",
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useAColAsAxis: false,
          label: "date",
          byChannel: by === "channel",
          compareDates: false,
          groupByCol: false,
          col: by,
          convertTo,
        };

      if (["DoughnutChart", "PieChart"].includes(type) && by === "metric")
        return {
          xAxis: "resource",
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useAColAsAxis: false,
          label: "date",
          byChannel: by === "channel",
          compareDates: false,
          groupByCol: true,
          col: by === "metric" ? "resource" : by,
          convertTo,
        };

      if (["DoughnutChart", "PieChart"].includes(type) && by !== "metric")
        return {
          xAxis: by,
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useAColAsAxis: false,
          label: "date",
          byChannel: by === "channel",
          compareDates: false,
          groupByCol: false,
          col: by,
          convertTo,
        };
      if (["TotalChart"].includes(type))
        return {
          xAxis: "metric",
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useAColAsAxis: false,
          label: "date",
          byChannel: false,
          compareDates: false,
          groupByCol: false,
          col: "metric",
          convertTo,
        };

      if (type === "SankeyChart") {
        return {
          xAxis: "parentId",
          excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
            excluded || []
          ),
          useAColAsAxis: false,
          label: "date",
          byChannel: false,
          compareDates: false,
          groupByCol: false,
          col: "parentId",
          parent: parent || "parentId",
          child: child || "id",
          flow: flow || "flow",
          convertTo,
        };
      }

      return {
        xAxis: "date",
        excluded: ["datetimeInterval", "date", "resource", "channel"].concat(
          excluded || []
        ),
        useAColAsAxis: false,
        label: "date",
        byChannel: false,
        compareDates: false,
        groupByCol: false,
        col: "resource",
        convertTo,
      };
    },

    byRows() {
      const chart = this.$props.chart;
      const configs = this.configs;
      let chartData = {};
      let labels = this.labels();
      let datasets = [];
      const legendKeys = this.nameCols.filter(
        key =>
          key !== configs.xAxis &&
          !configs.excluded.includes(key) &&
          (configs.useSecondFieldAsLabel ? key !== configs.label : true) &&
          (["DoughnutChart", "PieChart"].includes(chart.type) &&
          chart.source === "query"
            ? key !== configs.col
            : true)
      );

      legendKeys.forEach((key, index) => {
        let colors;
        if (
          ["DoughnutChart", "PieChart"].includes(chart.type) ||
          configs.colorByCol
        ) {
          colors = [];
          for (let row of Object.keys(this.rowsData)) {
            let color =
              this.$props.chart.colors[row] ||
              this.colorOfVarWithOpacity(
                channels.find(e => e.value === row)?.background
              ) ||
              this.colorOfVarWithOpacity(
                this.$props.statuses.find(e => e.name === row)?.color
              ) ||
              this.generateRandomColor();
            if (this.$props.chart.colors[row] === undefined)
              this.$emit("newColor", { [row]: color });
            colors.push(color);
          }
        } else {
          let color =
            this.$props.chart.colors[key] ||
            this.colorOfVarWithOpacity(
              this.channels.find(e => e.value === key)?.background
            ) ||
            this.colorOfVarWithOpacity(
              this.$props.statuses.find(e => e.name === key)?.color
            ) ||
            this.generateRandomColor();
          if (this.$props.chart.colors[key] === undefined)
            this.$emit("newColor", { [key]: color });
          colors = color;
        }
        let label =
          this.getLabel(
            configs.useSecondFieldAsLabel
              ? this.data[index][configs.label]
              : key
          ) || key;

        const data = this.data.map(row => row[key] || 0);
        this.maxY = Math.max(this.maxY, ...data);
        datasets.push({
          label: label,
          backgroundColor: colors,
          data: data,
          fill: true,
          borderColor: colors,
          borderWidth: this.$props.chart.configs.borderWidth
            ? this.$props.chart.configs.borderWidth
            : 0.5,
          tension: 0.05,
          borderRadius: ["BarChart", "LineChart"].includes(
            this.$props.chart.type
          )
            ? 12
            : 0,
          yAxisId: "y-axis-" + key,
        });
      });

      chartData.labels = labels;
      chartData.datasets = datasets;

      return chartData;
    },

    labels() {
      const chart = this.$props.chart;
      const configs = this.configs;
      let labels = [];
      if (chart.source !== "query" && configs.xAxis === "metrics") {
        labels = this.totalMetrics.map(e => this.getLabel(e.id));
      } else {
        labels = this.data.map(row => row[configs.xAxis || configs.col]);
      }
      labels =
        configs.xAxis === "date" || configs.compareDates
          ? labels.map(d => {
              const date = this.luxon().fromFormat(d, "yyyy-MM-dd HH:mm:ss");

              return date.isValid ? date.ts : d;
            })
          : labels;

      return [...new Set(labels)];
    },

    byCols() {
      const configs = this.configs;
      let chartData = {};
      let datasets = [];

      const rows = this.rowsData;
      const labels = this.nameCols.filter(
        key =>
          key !== configs.xAxis &&
          !configs.excluded.includes(key) &&
          (configs.useSecondFieldAsLabel ? key !== configs.label : true)
      );

      if (configs.useAColAsAxis) {
        return this.byColsWithRow(rows);
      }

      chartData.labels = labels.map(e => this.getLabel(e));

      for (let row of Object.keys(rows)) {
        let data = [];
        let colors = [];
        for (let col of labels) {
          data.push(
            rows[row].reduce((a, b) => {
              if (b[col]) return a + b[col];
              else return a;
            }, 0)
          );
          if (
            ["DoughnutChart", "PieChart"].includes(this.$props.chart.type) ||
            configs.colorByCol
          ) {
            let color =
              this.$props.chart.colors[col] ||
              this.colorOfVarWithOpacity(
                this.channels.find(e => e.value === col)?.background
              ) ||
              this.colorOfVarWithOpacity(
                this.$props.statuses.find(e => e.name === col)?.color
              ) ||
              this.generateRandomColor();
            if (this.$props.chart.colors[col] === undefined)
              this.$emit("newColor", { [col]: color });
            colors.push(color);
          } else {
            let color =
              this.$props.chart.colors[row] ||
              this.colorOfVarWithOpacity(
                this.channels.find(e => e.value === row)?.background
              ) ||
              this.colorOfVarWithOpacity(
                this.$props.statuses.find(e => e.name === row)?.color
              ) ||
              this.generateRandomColor();
            if (this.$props.chart.colors[row] === undefined)
              this.$emit("newColor", { [row]: color });
            colors.push(color);
          }
        }
        this.maxY = Math.max(this.maxY, ...data);
        datasets.push({
          label: row,
          backgroundColor: colors,
          data: data,
          fill: true,
          borderColor: this.$props.chart.colors[row],
          borderRadius: ["BarChart", "LineChart"].includes(
            this.$props.chart.type
          )
            ? 12
            : 0,
          borderWidth: 0.5,
          tension: 0.05,
        });
      }

      chartData.datasets = datasets;

      return chartData;
    },

    byColsWithRow(rows) {
      const configs = this.configs;
      let chartData = {};
      let datasets = [];
      let labels = [];
      let cols = this.nameCols.filter(
        key =>
          key !== configs.xAxis &&
          !configs.excluded.includes(key) &&
          (configs.useSecondFieldAsLabel ? key !== configs.label : true)
      );
      const addMetric = cols.length > 1;
      for (let col of cols) {
        for (let row of Object.keys(rows)) {
          labels = labels.concat(rows[row].map(e => e[configs.xAxis]));
          let color =
            this.$props.chart.colors[row] ||
            this.colorOfVarWithOpacity(
              channels.find(e => e.value === row)?.background
            ) ||
            this.colorOfVarWithOpacity(
              this.$props.statuses.find(e => e.name === row)?.color
            ) ||
            this.generateRandomColor();
          if (this.$props.chart.colors[row] === undefined)
            this.$emit("newColor", { [row]: color });

          const data = rows[row].map(e => e[col] || 0);
          this.maxY = Math.max(this.maxY, ...data);
          datasets.push({
            label: this.getLabel(row).concat(
              addMetric ? "-" + this.getLabel(col) : ""
            ),
            backgroundColor: color,
            data: data,
            fill: true,
            borderColor: this.$props.chart.colors[row],
            borderWidth: 0.5,
            tension: 0.05,
            borderRadius: this.$props.chart.configs.borderRadius ? 12 : 0,
          });
        }
      }

      // labels = [...new Set(labels)];

      chartData.labels = [...new Set(labels)].map(e => this.getLabel(e));
      chartData.datasets = datasets;

      return chartData;
    },

    sankeyData() {
      const { parent, child, flow } = this.configs;
      let data = {
        labels: {},
        fullLabels: {},
        data: [],
        columns: {},
        colors: JSON.parse(JSON.stringify(this.$props.chart.colors)) || {},
      };

      this.data.forEach(row => {
        //Is root
        if (row[parent] == undefined) {
          let label = this.getLabel(row.label);
          data.labels[row[child]] =
            label.length > 12 ? label.slice(0, 12) + "..." : label;
          data.fullLabels[row[child]] = label;
          if (!data.colors[row[child]]) {
            data.colors[row[child]] = this.generateRandomColor();
            this.$emit("newColor", { [row[child]]: data.colors[row[child]] });
          }

          data.columns[row[child]] = 0;
        } else {
          if (!data.fullLabels[row.label]) {
            let label = this.getLabel(row.label);
            data.labels[row[child]] =
              label.length > 12 ? label.slice(0, 12) + "..." : label;
            data.fullLabels[row[child]] = label;
          }
          if (data.columns[row[child]] == undefined) {
            data.columns[row[child]] = row.column + 1;
          }

          if (!data.colors[row[child]]) {
            data.colors[row[child]] = this.generateRandomColor();
            this.$emit("newColor", { [row[child]]: data.colors[row[child]] });
          }

          data.data.push({
            parentId: row[parent],
            id: row[child],
            flow: row[flow],
          });
        }
      });

      return data;
    },

    generateRandomColor() {
      //in hexa
      let random = (
        "000000" + Math.floor(Math.random() * 16777215).toString(16)
      ).slice(-6);

      return "#" + random + "80";
    },

    getLabel(label) {
      if (
        this.$props.chart.configs &&
        this.$props.chart.configs.customLabels &&
        this.$props.chart.labels &&
        this.$props.chart.labels[label]
      ) {
        if (this.$i18n.te(label))
          return this.$i18n.t(this.$props.chart.labels[label]);

        return this.$props.chart.labels[label];
      }

      if (label === "date") {
        return label.charAt(0).toUpperCase() + label.slice(1);
      }
      if (
        this.$i18n.te(this.$props.chart.source + "Metrics." + label) ||
        this.$i18n.te(label)
      ) {
        return !this.$i18n.te(this.$props.chart.source + "Metrics." + label)
          ? this.$i18n.t(label)
          : this.$i18n.t(this.$props.chart.source + "Metrics." + label);
      }
      if (this.$i18n.te(label)) {
        return this.$i18n.t(label);
      }

      if (this.configs.xAxis === "date" || this.configs.compareDates) {
        const date = this.luxon().fromFormat(label, "yyyy-MM-dd HH:mm:ss");

        return date.isValid ? date.ts : label;
      }

      return label;
    },
    isValidDate(date) {
      return this.luxon().fromFormat(date, "yyyy-MM-dd HH:mm:ss").isValid;
    },

    //Data table
    changeItemsPerPage(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage;
    },
    previousPage() {
      this.pagination.page--;
    },
    nextPage() {
      this.pagination.page++;
    },
    setPaginationData(data) {
      this.pagination.totalRecords = data.length;
    },
    convertSecondsToTimeFormat(timeInSeconds) {
      timeInSeconds = timeInSeconds
        ? typeof timeInSeconds == "string"
          ? timeInSeconds.replace(".", "")
          : timeInSeconds
        : 0;

      timeInSeconds = timeInSeconds ? `${timeInSeconds}`.replace(",", "") : 0;
      timeInSeconds = Math.round(timeInSeconds);
      let hours = Math.floor(timeInSeconds / 3600);
      let minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
      let seconds = timeInSeconds - hours * 3600 - minutes * 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },

    toggleLine(disableds) {
      this.$refs.chart.$refs.chart.$children[0].chart.data.datasets.forEach(
        //eslint-disable-next-line
        (dataset, index) => {
          if (disableds.includes(dataset.label)) {
            dataset.hidden = true;
          } else {
            dataset.hidden = false;
          }
          this.$refs.chart.$refs.chart.$children[0].chart.update();
        }
      );
    },

    updateConfigs() {
      this.configs = this.handleConfig();
    },

    convertTo(value, convertTo) {
      if (convertTo === "time") return this.convertSecondsToTimeFormat(value);
      if (convertTo === "percentage") return value + "%";

      return value;
    },

    tooltipLabel(context) {
      {
        let { convertTo, stacked, noLabel } = this.$props.chart.configs;
        convertTo = convertTo || this.configs.convertTo;
        const { dataset, raw, dataIndex } = context;
        let label = "";

        if (
          dataset.label !== undefined &&
          dataset.label !== "undefined" &&
          !noLabel
        )
          label = dataset.label + ": ";

        if (convertTo) label += this.convertTo(raw, convertTo);
        else if (stacked) label += dataset.data[dataIndex];
        else label += raw;

        return label;
      }
    },

    tooltipTitle(context) {
      const { type, _unit } = context[0].chart.scales.x;

      if (type !== "time") return context[0].label;

      const localeFormats = {
        hour: luxon().TIME_SIMPLE, //Example : 1:30 PM
        day: { weekday: "long", month: "short", day: "numeric" }, //Example : Mon, Sep 4
        week: { weekday: "long", month: "short", day: "numeric" }, //Example : Monday, Sep 4
        month: luxon().DATE_FULL, //Example : September 2, 1986
      };

      const simpleFormats = {
        minute: "HH:mm:ss",
        hour: "T", //Example : 16:03
        day: "dd/MM/yyyy", //Example :  || D: 9/4/2015 (Localized)
        week: "ccc, D", //Example : Mon, 4
        month: "LLL yyyy", //Example : Sep 2015
        quarter: "[Q]q - yyyy", //Example : Q3 - 2015
        year: "yyyy", //Example : 2015
      };

      if (context[0].parsed.x === undefined || isNaN(context[0].parsed.x))
        return context[0].label;

      const time = this.luxon().fromMillis(context[0].parsed.x);

      if (time.isValid) {
        if (localeFormats[_unit])
          return time
            .setLocale(this.$i18n.locale)
            .toLocaleString(localeFormats[_unit]);

        return time.setLocale(this.$i18n.locale).toFormat(simpleFormats[_unit]);
      }

      return context[0].label;
    },

    colorOfVarWithOpacity(color) {
      let colorVar = colorOfVar(color);
      if (!colorOfVar) return null;

      const typeChart = this.$props.chart.type;

      if (typeChart === "LineChart" && colorVar) return colorVar + "80";

      return colorVar;
    },
  },

  watch: {
    nameCols(cols) {
      this.$emit("config", { cols: cols });
    },
    "$props.externalData": {
      handler: function (data) {
        this.data = data;
      },
      deep: true,
    },
  },
};
</script>
