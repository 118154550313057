var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ReactiveMenu',{ref:"reactiveMenu",attrs:{"hotkey":"alt+n"},on:{"hotkey":function($event){return _vm.openMenu()},"open":function($event){return _vm.focusElement()}},scopedSlots:_vm._u([{key:"activator",fn:function({ onMenu, attrs }){return [_c('v-badge',{attrs:{"content":_vm.notifications.length,"color":_vm.notifications.length ? 'primary' : 'transparent',"bottom":"","offset-x":"20","offset-y":"15"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c('v-btn',_vm._g(_vm._b({ref:"btnNotifications",attrs:{"disabled":_vm.useInboxStore.status === 'Already connected',"icon":"","role":"button","aria-label":_vm.$i18n.t('Notifications')}},'v-btn',attrs,false),{ ...onMenu, ...onTooltip }),[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t("Notifications")))])])],1)]}},{key:"content",fn:function(){return [_c('v-card',{attrs:{"width":_vm.$vuetify.breakpoint.smAndDown ? '' : '370px',"elevation":_vm.$vuetify.breakpoint.smAndDown ? '0' : ''}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-toolbar',{staticClass:"notification-toolbar",style:({
            background: _vm.colorPerTheme(),
          }),attrs:{"flat":"","tile":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$i18n.t("Notifications"))+" ")]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"role":"button","icon":""},on:{"click":function($event){return _vm.openMenu()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()],1),(_vm.notifications.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pa-0 transparent",staticStyle:{"max-height":"400px","overflow":"auto"},attrs:{"two-line":"","role":"list"}},_vm._l((_vm.notifications),function(item,index){return _c('v-list-item',{key:index,staticClass:"text--secondary before-background",style:([
                {
                  'border-bottom':
                    index !== _vm.notifications.length - 1
                      ? _vm.$vuetify.theme.dark
                        ? 'solid thin rgba(255, 255, 255, 0.12)'
                        : 'solid thin rgba(0, 0, 0, 0.12)'
                      : null,
                },
              ]),attrs:{"role":"listitem"},on:{"click":function($event){item.options ? null : _vm.removeNotification(item)}}},[_c('v-list-item-content',{style:(!_vm.$vuetify.theme.dark ? 'color : black' : '')},[_c('span',[_vm._v(" "+_vm._s(item.textShow))]),(item.description)?_c('span',{staticClass:"mt-2"},[_vm._v(_vm._s(item.description))]):_vm._e(),(item.options)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.options.filter(o =>
                      item.options.includes(o.value)
                    )),function(option,index){return _c('v-btn',{key:index,staticClass:"pa-3 mr-3",attrs:{"role":"button","aria-label":option.text,"icon":"","color":option.color},on:{"click":function($event){$event.stopPropagation();return option.method(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(option.icon)+" ")])],1)}),1):_vm._e()]),_c('v-list-item-action',{staticClass:"align-self-start",style:(!_vm.$vuetify.theme.dark ? 'color : black' : '')},[_c('Timer',{attrs:{"start":_vm.luxon.fromFormat(item.date, 'yyyy-MM-dd TT')}})],1)],1)}),1)],1):_vm._e(),(!_vm.notifications.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('BotPlaceholder',{attrs:{"type":"relax"}})],1):_vm._e()],1),(_vm.notifications.length)?_c('v-row',{staticClass:"ma-0 pa-3 d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeAllNotification()}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }