var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$props.data)?_c('div',[(_vm.$props.actions.length > 1)?_c('v-menu',{attrs:{"offset-y":"","left":""},on:{"input":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":_vm.$props.xsmall,"small":_vm.$props.small,"role":"button","aria-label":"actions"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":_vm.$props.small}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,775824876)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.$props.actions.filter(action =>
          action.security ? action.security(_vm.$props.data) : true
        )),function(item,index){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.disabled ? !item.disabled() : true),expression:"item.disabled ? !item.disabled() : true"}],key:index,on:{"click":function($event){return item.method(_vm.$props.data)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":item
                ? typeof item.color === 'function'
                  ? item.color(_vm.$props.data)
                  : item.color
                : null}},[_vm._v(" "+_vm._s(item ? typeof item.icon === "function" ? item.icon(_vm.$props.data) : item.icon : null)+" ")])],1),_c('v-list-item-subtitle',{staticClass:"text-body-2",class:[`${item ? item.color : null}--text`]},[_vm._v(" "+_vm._s(item ? typeof item.text === "function" ? item.text(_vm.$props.data) : _vm.$i18n.t(item.text) : null)+" ")])],1)}),1)],1):_vm._e(),(_vm.$props.actions.length == 1)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":_vm.$props.xsmall,"aria-label":_vm.actions[0].text,"role":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$props.actions[0].method(_vm.$props.data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.$props.actions[0] ? _vm.$props.actions[0].color : null}},[_vm._v(" "+_vm._s(_vm.$props.actions[0] ? _vm.$props.actions[0].icon : null)+" ")])],1)]}}],null,false,2265499484)},[(_vm.$props.actions[0].text)?_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t(_vm.$props.actions[0].text))+" ")]):_vm._e()])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }