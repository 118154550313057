import Profile from "@/controllers/profile";
import { useAppStore, useAxios, validateSecurity, baseSchedule } from "@/utils";

export default class User {
  constructor(data = {}) {
    this.username = data.username || null;
    this.name = data.name || null;
    this.phone = data.phone || null;
    this.email = data.email || null;
    this.enabled = data.enabled === false ? false : true;
    this.profile = data.profile || null;
    this.security = data.security ? new Profile(data.security) : new Profile();
    this.validityDate = data.validityDate || null;
    this.expirationDate = data.expirationDate || null;
    this.temporary = !!data.validityDate || !!data.expirationDate;
    this.schedule =
      data.schedule && data.schedule.length ? data.schedule : baseSchedule();
    this.avatar = data.avatar || "";
    this.transport = data.transport || "WSS";
    this.encrypted = data.encrypted ? 1 : 0;
    this.language = data.language
      ? data.language
      : useAppStore().preference.language
      ? useAppStore().preference.language
      : "en";
    this.timezone =
      data.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.superuser = data.superuser || null;
    this.licenciator = data.licenciator || null;
  }

  get initials() {
    return this.getInitials();
  }

  getInitials() {
    if (this.name) {
      if (this.name.split(" ").length > 1) {
        return (
          this.name.substr(0, 1) +
          this.name.split(" ")[this.name.split(" ").length - 1].substr(0, 1)
        ).toUpperCase();
      } else {
        return this.name.substr(0, 1).toUpperCase();
      }
    } else {
      return null;
    }
  }

  static async get() {
    if (validateSecurity("users")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/users`,
        headers: {},
      });

      if (res.success) {
        res = res.res;
        let users = JSON.parse(res.request.response).result
          ? JSON.parse(res.request.response).result
          : [];

        users = users.map(e => {
          let user = e;

          return new User(user);
        });

        users.sort((a, b) => {
          var x = a.username.toLowerCase();
          var y = b.username.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }

          return 0;
        });

        return users;
      }
    }

    return [];
  }

  static async create(user) {
    user.superuser = user.superuser ? 1 : 0;
    if (validateSecurity("users", "create")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/users/id/${user.username}`,
        data: user,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async batch(batch) {
    if (validateSecurity("users", "create")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/users/import`,
        data: { batch: batch },
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async update(user) {
    if (validateSecurity("users", "update")) {
      user.superuser = user.superuser ? 1 : 0;
      const res = await useAxios({
        method: "PUT",
        url: `/api/users/id/${user.username}`,
        data: user,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async delete(user) {
    if (validateSecurity("users", "delete")) {
      user.superuser = user.superuser ? 1 : 0;
      const res = await useAxios({
        method: "DELETE",
        url: `/api/users/id/${user.username}`,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async getApiToken(user) {
    let res = await useAxios({
      method: "GET",
      url: `/api/auth/id/${user.username}/apiToken`,
      headers: {},
    });
    if (res.success) {
      res = res.res;

      return res.data.result;
    } else {
      return [];
    }
  }

  static async createdApiToken(user, id) {
    const res = await useAxios({
      method: "POST",
      url: `/api/auth/id/${id}/apiToken`,
      data: { username: user.username },
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  static async deleteApiToken(id, user) {
    const res = await useAxios({
      method: "DELETE",
      data: {
        username: user.username,
      },
      url: `/api/auth/id/${id}/apiToken`,
      headers: {},
    });

    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  static async changeAvatarUser(file, avatarObj, user) {
    const url = `/api/users/id/${user.username}/avatar?avatar="${JSON.stringify(
      avatarObj
    )}"`;

    const res = await useAxios({
      method: "POST",
      url: url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    return res;
  }
  static async changeMyAvatar(file, avatarObj, user, noavatar) {
    const url = `/api/me/id/${user.username}/avatar?avatar="${JSON.stringify(
      avatarObj
    )}"&noavatar="${noavatar}"`;

    const res = await useAxios({
      method: "POST",
      url: url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    return res;
  }

  static async getInbox(user) {
    let res = await useAxios({
      method: "POST",
      url: "/api/me/inbox",

      data: { username: [user] },
      headers: {},
    });
    if (res.success) {
      return res;
    } else {
      console.log(res);
    }
  }
  static async getStatuses(user) {
    let res = await useAxios({
      method: "POST",
      url: "/api/me/statuses",

      data: { username: [user] },
      headers: {},
    });
    if (res.success) {
      return res;
    } else {
      console.log(res);
    }
  }

  static async getDashboardText(username) {
    let res = await useAxios({
      method: "GET",
      url: "/api/me/dashboardAgentText?username=" + username,
      headers: {},
    });
    if (res.success) {
      return res;
    } else {
      console.log(res);
    }
  }

  static async getPasswordRegex() {
    let res = await useAxios({
      method: "GET",
      url: "/api/auth/passwordRegex",
      headers: {},
    });
    if (res.success) {
      return res.res.data.result.regex;
    } else {
      console.log(res);
    }
  }

  static async exportData(data, timezone) {
    let res = await useAxios({
      method: "POST",
      url: "/api/users/events",
      data: {
        ...data,
        timezone: timezone,
      },
      headers: {},
    });

    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  static async updatePreferences(preferences) {
    let res = await useAxios({
      method: "POST",
      url: "/api/me/preferences",
      data: preferences,
      headers: {
        Authorization: "Bearer " + useAppStore().user.token,
      },
    });

    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  static async getServerDate() {
    let res = await useAxios({
      method: "GET",
      url: "/api/me/serverDate",
      headers: {},
    });
    if (res.success) {
      return res.res.data.result.date;
    } else {
      console.log(res);
    }
  }
}
