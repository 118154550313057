import { useAxios } from "@/utils";

export default class Analytics {
  constructor() {}
  static async getCampaignQueueInteractions(data) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/campaignQueueInteractions?campaign=${data.name}&channels=${data.channels}`,
      headers: {},
      data: { campaigns: [data.name], channels: data.channels },
    });
    if (res.success) {
      let result = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : {};

      return result;
    } else {
      console.log(res);

      return [];
    }
  }

  static async getStatsFromCampaign(data) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/infoFromCampaign?campaign=${data.name}&channels=${data.channels}`,
      headers: {},
      data: { campaigns: [data.name], channels: data.channels },
    });
    if (res.success) {
      let result = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : {};

      return result;
    } else {
      console.log(res);

      return [];
    }
  }
  static async getCampaignsCurrentInfo(campaigns) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/campaignsCurrentInfo`,
      headers: {},
      data: { campaigns: campaigns },
    });
    if (res.success) {
      let result = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : {};

      return result;
    } else {
      console.log(res);

      return [];
    }
  }
  static async getUsers(users, showLicenciatorUsers) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/users`,
      headers: {},
      data: { users: users },
      params: {
        showLicenciatorUsers: showLicenciatorUsers,
      },
    });
    if (res.success) {
      let result = JSON.parse(res.res.request.response).result
        ? JSON.parse(res.res.request.response).result
        : [];

      return result;
    } else {
      return [];
    }
  }

  static async pauseAgent(campaign, agent) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/pauseAgent?campaign=${campaign}&agent=${agent}`,
      headers: {},
    });
    if (!res.success) {
      console.log(res);
    }

    return res;
  }
  static async unpauseAgent(campaign, agent) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/unpauseAgent?campaign=${campaign}&agent=${agent}`,
      headers: {},
    });
    if (!res.success) {
      console.log(res);
    }

    return res;
  }

  static async logoutAgent(campaign, agent) {
    let res = await useAxios({
      method: "POST",
      url: `/api/analytics/logoutAgent?campaign=${campaign}&agent=${agent}`,
      headers: {},
    });
    if (!res.success) {
      console.log(res);
    }

    return res;
  }

  static async search(search) {
    let res = await useAxios({
      method: "GET",
      url: `/api/me/search?search=${search}`,
      headers: {},
    });
    if (!res.success) {
      console.log(res);
    }

    return res;
  }
}
