<template>
  <v-app-bar
    :app="true"
    clipped-left
    flat
    :style="[
      {
        'margin-left': $vuetify.breakpoint.mdAndDown ? null : '50px',
      },
    ]"
  >
    <v-row no-gutters class="align-center fill-height">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndDown && navigation"
        icon
        :disabled="$props.disableMenu"
        @click="$emit('openMenu')"
        role="menu"
        aria-label="menu"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <img
        :src="logo"
        style="cursor: pointer"
        height="100%"
        :width="$vuetify.breakpoint.smAndDown ? '40px' : '150px'"
        @click="$emit('home')"
        alt="uContact_logo"
        role="img"
      />
      <v-btn
        role="button"
        :aria-label="$i18n.t('Search')"
        icon
        @click="openSearch"
        v-hotkey="{
          'alt+s': () => {
            openSearch();
          },
          'alt+a': () => {
            phoneAnswer();
          },
          'alt+h': () => {
            phoneHangUp();
          },
        }"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-dialog
        v-model="dialogs.search"
        content-class="searchDialog"
        transition="dialog-top-transition"
        role="dialog"
      >
        <SearchBar
          ref="searchBar"
          @close="
            dialogs.search = false;
            searchOpen = false;
          "
          @changeRoute="$emit('changeRoute')"
          :open="searchOpen"
        />
      </v-dialog>

      <v-spacer />

      <div class="justify-end d-flex">
        <PhoneAnswerButton
          ref="btnPhoneAnswer"
          v-if="
            usePhoneStore.callState === 'ringing' ||
            usePhoneStore.callState === 'onCall'
          "
          @changeRoute="$emit('changeRoute')"
        />

        <WrapupButton class="ml-6" v-if="useInboxStore.wrapup" />

        <Breaks ref="statusesMenu" @close="closeMenu" />

        <Notifications
          ref="notificationsMenu"
          @close="closeMenu"
          @changeRoute="$emit('changeRoute')"
        />

        <UserMenu
          ref="userMenu"
          @connectInbox="$emit('connectInbox')"
          @close="closeMenu"
          @logout="$emit('logout')"
        />
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
import { usePhoneStore, useInboxStore, useAppStore } from "@/utils";
import UserMenu from "@/components/menu/userMenu/UserMenu";
import SearchBar from "@/components/menu/searchBar/SearchBar";
import PhoneAnswerButton from "@/components/menu/PhoneAnswerButton";
import WrapupButton from "@/components/menu/WrapupButton";
import Notifications from "@/components/menu/notifications/Notifications";
import Breaks from "@/components/menu/breaks/Breaks";

export default {
  name: "AppMenuBar",

  components: {
    UserMenu,
    PhoneAnswerButton,
    Notifications,
    Breaks,
    SearchBar,
    WrapupButton,
  },

  props: { disableMenu: { type: Boolean } },

  data() {
    return {
      usePhoneStore: usePhoneStore(),
      useInboxStore: useInboxStore(),
      useAppStore: useAppStore(),
      audio: null,
      dialogs: { search: false },
      searchOpen: false,
      navigation: false,
      logo: require(`@/assets/logo/uContact_${
        this.$vuetify.breakpoint.smAndDown ? "iso_light" : "light"
      }.svg`),
    };
  },
  created() {
    this.navigation =
      useAppStore().user.superuser ||
      useAppStore().profile.controllers.length ||
      this.$route.name == "CRM";
  },
  methods: {
    openSearch() {
      this.dialogs.search = true;

      this.$nextTick(() => {
        this.$refs.searchBar.focusInput();
      });

      setTimeout(() => {
        this.searchOpen = true;
      }, 100);
    },

    phoneAnswer() {
      if (this.usePhoneStore.callState === "ringing") {
        this.$refs.btnPhoneAnswer?.answer();
      }
    },

    phoneHangUp() {
      if (this.usePhoneStore.callState === "onCall") {
        this.$refs.btnPhoneAnswer?.hangUp();
      }
    },

    closeMenu(menu) {
      const options = [
        {
          name: "notification",
          action: () => {
            this.$refs.statusesMenu.closeMenu();
            this.$refs.userMenu.closeMenu();
            this.$refs.notificationsMenu.closeMenu();
          },
        },
        {
          name: "status",
          action: () => {
            this.$refs.notificationsMenu.closeMenu();
            this.$refs.userMenu.closeMenu();
          },
        },
        {
          name: "user",
          action: () => {
            this.$refs.statusesMenu.closeMenu();
            this.$refs.notificationsMenu.closeMenu();
          },
        },
      ];

      options.find(e => e.name === menu).action();
    },
  },

  watch: {
    "usePhoneStore.callState"() {
      let sound = require(`../../assets/sounds/${
        useAppStore().preference.sounds.ringtone
      }.ogg`);
      this.audio = this.audio == null ? new Audio(sound) : this.audio;
      if (this.usePhoneStore.callState == "ringing") this.audio.play();
      const interval = setInterval(() => {
        if (this.usePhoneStore.callState == "ringing") {
          this.audio.play();
        } else {
          clearInterval(interval);
        }
      }, 4000);
    },
  },
};
</script>

<style>
.searchDialog {
  max-height: 100% !important;
  height: 100%;
  margin: 0px !important;
  box-shadow: none !important;
}
</style>
