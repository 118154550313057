var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",staticStyle:{"width":"100%","position":"relative"},attrs:{"cols":"12","id":_vm.id}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$attrs.value),expression:"$attrs.value"}],staticClass:"verticalBar",style:(`width: ${_vm.height};
        height: 60px; 
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        background-image: linear-gradient(to right, ${_vm.frontColor} 0%, ${_vm.frontColor} ` +
        _vm.backgroundWidth +
        `%, ${_vm.backColor} ` +
        _vm.backgroundWidth +
        '% );'),attrs:{"type":"range","readonly":"","min":_vm.min,"max":_vm.max},domProps:{"value":(_vm.$attrs.value)},on:{"input":function($event){return _vm.$emit('input', _vm.$attrs.value)},"__r":function($event){return _vm.$set(_vm.$attrs, "value", $event.target.value)}}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }