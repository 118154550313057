var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormTabsLayout',{staticClass:"container-title",attrs:{"title":"Preferences","showActions":false,"size":"lg"},on:{"back":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"tabs",fn:function(){return [_c('v-tabs',{attrs:{"vertical":!_vm.$vuetify.breakpoint.smAndDown,"icons-and-text":_vm.$vuetify.breakpoint.smAndDown,"show-arrows":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(tab,index){return _c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(tab.show()),expression:"tab.show()"}],key:index,class:[{ 'justify-start': !_vm.$vuetify.breakpoint.smAndDown }]},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(tab.icon())+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$i18n.t(tab.text))+" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',[_vm._v(" "+_vm._s(tab.icon())+" ")]):_vm._e()],1)}),1)]},proxy:true},{key:"form",fn:function(){return [_c('v-tabs-items',{staticStyle:{"height":"100%"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[_c('v-row',{staticClass:"overflow-y-auto overflow-x-hidden",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-2 pa-3",attrs:{"cols":"12"}},[_c('InputRange',{attrs:{"label":_vm.$i18n.t('Volume')},on:{"input":_vm.rangeChange},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary font-weight-bold"},[_vm._v(_vm._s(_vm.$i18n.t("Interaction sound")))])]),_c('v-col',{staticClass:"pa-3 d-flex",staticStyle:{"gap":"2rem","margin-left":"2rem"},attrs:{"cols":"12"}},_vm._l((_vm.notificationSounds),function(item,index){return _c('v-card',{key:'notification-' + index,staticClass:"pa-3 text-center",class:[
                {
                  primary:
                    _vm.useAppStore().preference.sounds.interaction ===
                    item.value,
                },
              ],staticStyle:{"background-color":"var(--v-background-base)","border-radius":"1rem"},attrs:{"outlined":"","flat":"","dark":_vm.useAppStore().preference.sounds.interaction === item.value},on:{"click":function($event){return _vm.updateSound('interaction', item.value)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.lgAndDown ? '30' : '40'}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary font-weight-bold"},[_vm._v(_vm._s(_vm.$i18n.t("Message sound")))])]),_c('v-col',{staticClass:"pa-3 d-flex",staticStyle:{"gap":"2rem","margin-left":"2rem"},attrs:{"cols":"12"}},_vm._l((_vm.notificationSounds),function(item,index){return _c('v-card',{key:'message-' + index,staticClass:"pa-3 text-center",class:[
                {
                  primary:
                    _vm.useAppStore().preference.sounds.message === item.value,
                },
              ],staticStyle:{"background-color":"var(--v-background-base)","border-radius":"1rem"},attrs:{"outlined":"","flat":"","dark":_vm.useAppStore().preference.sounds.message === item.value},on:{"click":function($event){return _vm.updateSound('message', item.value)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.lgAndDown ? '30' : '40'}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary font-weight-bold"},[_vm._v(_vm._s(_vm.$i18n.t("Ringing sound")))])]),_c('v-col',{staticClass:"pa-3 d-flex",staticStyle:{"gap":"2rem","margin-left":"2rem"},attrs:{"cols":"12"}},_vm._l((_vm.ringSounds),function(item,index){return _c('v-card',{key:'ringing-' + index,staticClass:"pa-3 text-center",class:[
                {
                  primary:
                    _vm.useAppStore().preference.sounds.ringtone === item.value,
                },
              ],staticStyle:{"background-color":"var(--v-background-base)","border-radius":"1rem"},attrs:{"outlined":"","flat":"","dark":_vm.useAppStore().preference.sounds.ringtone === item.value},on:{"click":function($event){return _vm.updateSound('ringtone', item.value)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.lgAndDown ? '30' : '40'}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)}),1)],1)],1),_c('v-tab-item',[_c('v-row',{staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary font-weight-bold"},[_vm._v(_vm._s(_vm.$i18n.t("Theme mode")))])]),_c('v-col',{staticClass:"pa-3 d-flex justify-center flex-wrap",staticStyle:{"gap":"8px"},attrs:{"cols":"12"}},_vm._l(([
                { value: false, icon: 'mdi-weather-sunny', text: 'Light' },
                { value: true, icon: 'mdi-weather-night', text: 'Dark' },
              ]),function(item,index){return _c('v-card',{key:'theme-' + index,staticClass:"rounded-xl pa-3 text-center",class:[
                {
                  primary: _vm.$vuetify.theme.dark === item.value,
                },
              ],staticStyle:{"aspect-ratio":"1","min-height":"110px"},attrs:{"outlined":"","flat":"","dark":_vm.$vuetify.theme.dark === item.value},on:{"click":function($event){return _vm.changeThemeMode(item.value)}}},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"50"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$i18n.t(item.text))+" ")])],1)}),1),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary font-weight-bold"},[_vm._v(_vm._s(_vm.$i18n.t("Theme color")))])]),_c('v-col',{staticClass:"pa-3 d-flex justify-center flex-wrap",staticStyle:{"gap":"2rem"},attrs:{"cols":"12"}},_vm._l((_vm.colorList),function(color,index){return _c('v-hover',{key:'color-' + index,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"rounded-xl",staticStyle:{"height":"60px","aspect-ratio":"1"},style:([
                  {
                    'background-color': !_vm.$vuetify.theme.dark
                      ? color.light
                      : color.dark,
                  },
                ]),attrs:{"flat":"","outlined":""},on:{"click":function($event){return _vm.changeThemeColor(color)}}},[(
                    hover ||
                    _vm.$vuetify.theme.themes.light.primary === color.light
                  )?_c('v-fade-transition',[_c('v-overlay',{staticClass:"color-overlay",staticStyle:{"position":"absolute","z-index":"1","height":"100%"},attrs:{"color":"transparent","opacity":"0.2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-check")])],1)],1):_vm._e()],1)]}}],null,true)})}),1)],1)],1),_c('v-tab-item',{staticClass:"fill-height"},[_c('div',{staticClass:"fill-height d-flex flex-column justify-center",staticStyle:{"align-items":"center","margin":"0 40px"}},[_c('div',{staticClass:"text-center card-flag-container"},_vm._l((_vm.languages),function(lang,index){return _c('div',{key:index,staticClass:"pa-6 card-flag",class:[
                _vm.useAppStore().preference.language === lang.value
                  ? 'primary white--text'
                  : 'shadow-md',
              ],on:{"click":function($event){return _vm.changeLanguage(lang.value)}}},[_c('v-row',{staticClass:"no-gutters",staticStyle:{"height":"fit-content","min-width":"78px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"bg-white",attrs:{"aspect-ratio":4 / 3,"cover":"","src":lang.svg}})],1),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold",style:({
                      color:
                        _vm.useAppStore().preference.language !== lang.value
                          ? 'var(--text-color)'
                          : '',
                    })},[_vm._v(" "+_vm._s(_vm.$i18n.t(lang.text))+" ")])])],1)],1)}),0)])])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }