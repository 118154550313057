import { useAxios, dateToLocalTime, validateSecurity } from "@/utils";
import Flow, { FlowHistoric } from "@/controllers/flow";

export default class Blacklist {
  constructor(data = {}) {
    this.campaign = data.campaign;
    this.clientId = data.clientId;
  }
  static async getTotalRecords() {
    if (validateSecurity("blacklist")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/blacklist/total`,
        headers: {},
      });
      if (res.success) {
        let total = JSON.parse(res.res.request.response).result.total
          ? JSON.parse(res.res.request.response).result.total
          : 1;

        return total;
      } else {
        console.log(res);
      }
    }

    return 1;
  }
  static async getPage(index = 0, rows = 15, search = "") {
    if (validateSecurity("blacklist")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/blacklist?index=${index}&rows=${rows}&search=${search}`,
        headers: {},
      });
      if (res.success) {
        return JSON.parse(res.res.request.response);
      } else {
        console.log(res);
      }
    }

    return [];
  }

  static async batch(batch) {
    if (validateSecurity("blacklist")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/blacklist/batch`,
        data: { batch: batch },
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async deleteByColumn(column, value) {
    if (validateSecurity("blacklist", "delete")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/blacklist/deleteByColumn`,
        data: { column: column, value: value },
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async add(blacklist) {
    if (validateSecurity("blacklist", "create")) {
      const res = await useAxios({
        method: "POST",
        url: `/api/blacklist/id/${blacklist.campaign}|${blacklist.clientId}`,
        data: blacklist,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async update(blacklist) {
    if (validateSecurity("blacklist", "update")) {
      const res = await useAxios({
        method: "PUT",
        url: `/api/blacklist/id/${blacklist.campaign}|${blacklist.clientId}`,
        data: blacklist,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async delete(blacklist) {
    if (validateSecurity("blacklist", "delete")) {
      const res = await useAxios({
        method: "DELETE",
        url: `/api/blacklist/id/${blacklist.campaign}|${blacklist.clientId}`,
        data: blacklist,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }

  static async getHistoricPage(index = 0, rows = 15, searchParam = "") {
    if (validateSecurity("blacklist")) {
      let search = searchParam ? searchParam.replace("+", "%2B") : "";
      let res = await useAxios({
        method: "GET",
        url: `/api/blacklist/historic?index=${index}&rows=${rows}&search=${search}`,
        headers: {},
      });
      if (res.success) {
        let out = JSON.parse(res.res.request.response);
        out.result.forEach(item => {
          item.date = dateToLocalTime(item.date).toFormat(
            "yyyy-MM-dd HH:mm:ss"
          );
        });

        return out;
      } else {
        console.log(res);
      }
    }

    return [];
  }

  static async getFlow() {
    if (validateSecurity("blacklist")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/blacklist/flow`,
        headers: {},
      });
      if (res.success) {
        let flow = JSON.parse(res.res.request.response).result
          ? JSON.parse(res.res.request.response).result
          : { ...new Flow(), type: "blacklist" };

        return new Flow(flow);
      } else {
        console.log(res);
      }
    }

    return { ...new Flow(), type: "blacklist" };
  }

  static async updateFlow(flow) {
    if (validateSecurity("blacklist")) {
      flow.type = "blacklist";
      const res = await useAxios({
        method: "PUT",
        url: `/api/blacklist/flow`,
        data: flow,
        headers: {},
      });

      if (!res.success) {
        console.log(res.res);
      }

      return res;
    }
  }
  static async getFlowHistoricPage(flow, index, rows) {
    if (validateSecurity("blacklist")) {
      let res = await useAxios({
        method: "GET",
        url: `/api/blacklist/flowHistoric?flow=${flow}&index=${index}&rows=${rows}`,
        headers: {},
      });
      if (res.success) {
        let flows = JSON.parse(res.res.request.response).result
          ? JSON.parse(res.res.request.response).result
          : [];
        flows = flows.map(c => new FlowHistoric(c));

        flows.sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }

          return 0;
        });

        return flows;
      }
    }

    return [];
  }
}
