import { defineStore } from "pinia";
import {
  useAppStore,
  inbox,
  dateToLocalTime,
  useAxios,
  usePhoneStore,
} from "@/utils";
import Phonebook from "@/controllers/phonebook";

const defPhoneStates = () => ({
  muted: false,
  held: false,
});

export const useInboxStore = defineStore("inbox", {
  state: () => {
    return {
      drafts: {
        email: {},
        sms: {},
        whatsapp: {},
        telephony: {},
      },
      interactions: [],
      ringingInteractions: [],
      parkedCalls: [],
      historic: [],
      ws: {},
      contacts: [],
      campaigns: [],
      status: "Reconnecting",
      pause: {
        breaks: [],
        status: {
          name: "",
        },
      },
      error: null,
      openInteraction: null,
      hideMenu: false,
      favorites: [],
      notifications: [],
      dispositions: [],
      phonebook: [],
      unreadMessageInteractionOpen: null,
      loadingForms: {},
      loadingFormStatus: {},
      serveError: false,
      servedForm: null,
      codeServer: "",
      spying: "",
      wrapup: false,
      wrapupEnd: null,
      unite: false,
      conferences: [],
      inConference: false,
      hoveredMenu: false,
      preMessage: [],
      phoneStates: defPhoneStates(),
      spyingCall: "",
    };
  },

  actions: {
    setUnite(unite) {
      this.unite = unite;
    },

    setWrapup(wrapup, end) {
      this.wrapup = wrapup;
      this.wrapupEnd = end ? dateToLocalTime(end) : null;
    },
    setSpying(username) {
      this.spying = username;
    },
    setStatus(status) {
      this.status = status;
    },
    async setPauseStatus(status, start, serverDate) {
      useAppStore().updateServerDate(serverDate);
      if (this.spying) return;
      if (status == "") {
        this.pause.status = { name: "" };
      } else if (status == "Supervisor") {
        this.pause.status = {
          name: "Supervisor",
          icon: "mdi-monitor-eye",
          start: dateToLocalTime(start),
          color: "var(--v-n2p-purple-base)",
        };
      } else if (status == "Unavailable") {
        this.pause.status = {
          name: "Unavailable",
          icon: "mdi-cancel",
          start: dateToLocalTime(start),
          color: "var(--v-error-base)",
        };
      } else {
        let pause = null;
        if (!this.pause.breaks.length) {
          let aux = await inbox().getStatuses();
          pause = aux.find(elem => elem.name == status);
        } else {
          pause = this.pause.breaks.find(elem => elem.name == status);
        }

        if (pause) {
          this.pause.status = pause;
          this.pause.status.start = dateToLocalTime(start);
          if (serverDate)
            this.pause.status.serverDate = dateToLocalTime(serverDate);
        }
      }
    },
    setError(error) {
      this.error = error;
    },
    getInteraction(guid) {
      return this.interactions.find(interaction => interaction.guid == guid);
    },
    addInteraction(interaction, transferDispositions) {
      let parsedInteraction = parseInteraction(
        interaction,
        transferDispositions
      );
      let findInteraction = this.interactions.find(
        e => e.fullId == parsedInteraction.fullId
      );

      if (findInteraction) {
        this.interactions = this.interactions.map(e =>
          e.fullId == parsedInteraction.fullId ? parsedInteraction : e
        );

        return "updated";
      } else {
        this.interactions.push(parsedInteraction);
      }

      return "added";
    },
    reloadContacts(contacts) {
      this.contacts = [];
      Object.getOwnPropertyNames(contacts).forEach(username => {
        this.addContact(contacts[username]);
      });
    },
    addContact(contact) {
      if (
        !this.contacts.find(
          contactName => contactName.username == contact.username
        )
      ) {
        contact.fullId =
          "0|" +
          [contact.username, useAppStore().user.username].sort().join("|");
        this.contacts.push(contact);
      }
    },
    updateContactAvatar(contact) {
      this.contacts.find(
        contactName => contactName.username == contact.username
      ).avatar = contact.avatar;
    },
    removeContact(contact) {
      this.contacts = this.contacts.filter(
        contactItem => contactItem.username != contact
      );
    },
    addCampaign(campaign) {
      if (
        !this.campaigns.find(campaignName => campaignName.name == campaign.name)
      ) {
        campaign.fullId = "0|" + campaign.name;
        this.campaigns.push(campaign);
      }
    },
    removeCampaign(campaign) {
      this.campaigns = this.campaigns.filter(
        campaignItem => campaignItem.name != campaign
      );
    },
    addParkCall(parkCall) {
      if (
        !this.parkedCalls.find(parkedCallItem =>
          parkCall.guid
            ? parkedCallItem.guid == parkCall.guid
            : (parkedCallItem.clientId = parkCall.clientId)
        )
      ) {
        this.parkedCalls.push(parseParkCall(parkCall));
      }
    },
    removeParkCall(guid) {
      this.parkedCalls = this.parkedCalls.filter(
        parkedCall => parkedCall.interaction.guid != guid
      );
    },
    addHistoric(historicInteraction) {
      if (
        !this.interactions.find(
          interaction =>
            interaction.guid == historicInteraction.guid &&
            interaction.interactionId &&
            interaction.interactionId == historicInteraction.id
        )
      ) {
        this.historic.push(parseHistoric(historicInteraction));
      }
    },
    clearHistoric() {
      this.historic = [];
    },
    removeInteraction(guid, interactionId) {
      guid = interactionId ? interactionId + "|" + guid : guid;

      let interaction = this.interactions.find(i => i.fullId == guid);
      if (
        interaction &&
        interaction.channel == "telephony" &&
        this.spying &&
        this.spyingCall == interaction.guid
      ) {
        this.setSpyingCall("");
      }
      this.interactions = this.interactions.filter(
        interaction => interaction.fullId != guid
      );
    },
    addRingingInteraction(interaction) {
      if (
        !this.ringingInteractions.find(
          rinteraction => rinteraction.guid == interaction.guid
        )
      ) {
        this.ringingInteractions.push(parseRingingInteraction(interaction));
      }
    },
    removeRingingInteraction(guid) {
      this.ringingInteractions = this.ringingInteractions.filter(
        interaction => interaction.guid != guid
      );
    },
    finishInteraction(guid, finishDate) {
      let storeInteraction = this.interactions.find(
        interaction => interaction.guid == guid && !interaction.finished
      );
      if (!storeInteraction) return;
      if (
        storeInteraction.channel == "telephony" &&
        this.spyingCall == storeInteraction.guid
      )
        this.setSpyingCall("");
      storeInteraction.finished = true;
      storeInteraction.finishDate = dateToLocalTime(finishDate).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    sentDispositions(guid, interactionId) {
      guid = interactionId ? interactionId + "|" + guid : guid;
      this.interactions.find(
        interaction => interaction.fullId == guid
      ).needsDispositions = false;
    },
    addMessage(guid, message) {
      let interaction = this.interactions.find(
        interaction => interaction.guid == guid
      );
      if (interaction) {
        interaction.clientName = message.clientName;
        message.date = message.date
          ? dateToLocalTime(message.date).toFormat("yyyy-MM-dd HH:mm:ss")
          : null;

        interaction.messages.push(message);
        interaction.lastMessage = message;

        let messageContent = document.getElementById(
          "messageInteractionContent"
        );

        if (messageContent) {
          if (
            messageContent.scrollTop + messageContent.offsetHeight <=
            messageContent.scrollHeight - 100
          ) {
            this.unreadMessageInteractionOpen
              ? this.unreadMessageInteractionOpen++
              : (this.unreadMessageInteractionOpen = 1);
          }
        }

        if (
          message.source != useAppStore().user.username &&
          this.openInteraction != interaction.guid
        ) {
          inbox().playSound(useAppStore().preference.sounds.message);
        }
      }
    },
    loadHistoricMessages(guid, messages) {
      let histroicInteractions = this.historic.filter(i => i.guid == guid);
      let loadedMessages = histroicInteractions.reduce((acc, interaction) => {
        if (!acc || acc.messages.length < interaction.messages.length)
          return interaction;

        return acc;
      }, null)?.messages;

      if (!loadedMessages) return;

      let addHistoricMessage = message => {
        message.date = message.date
          ? dateToLocalTime(message.date).toFormat("yyyy-MM-dd HH:mm:ss")
          : null;
        loadedMessages.unshift(message);
      };

      messages.forEach(message => addHistoricMessage(message));

      for (const interaction of histroicInteractions)
        interaction.messages = loadedMessages;
    },

    clearMessages(guid) {
      for (const interaction of this.interactions.filter(i => i.guid == guid)) {
        interaction.messages = [];
      }
    },
    loadMessage(guid, message) {
      message.date = message.date
        ? dateToLocalTime(message.date).toFormat("yyyy-MM-dd HH:mm:ss")
        : null;

      for (const interaction of this.interactions.filter(i => i.guid == guid)) {
        interaction.messages.unshift(message);
        if (interaction.messages.length == 1) interaction.lastMessage = message;
      }
    },
    updateMessage(guid, data) {
      let message = this.interactions
        .find(interaction => interaction.guid == guid)
        .messages.find(message => message.channelId == data.id);

      if (message) {
        message.status = data.status;
      }
    },
    updateContactId(guid, data) {
      this.interactions.find(
        interaction => interaction.guid == guid
      ).contactId = data.contactId;
    },
    clear() {
      this.interactions = [];
      this.ringingInteractions = [];
      this.historic = [];
      this.parkedCalls = [];
      this.favorites = [];
      this.contacts = [];
      this.campaigns = [];
      this.ws = {};
      if (!this.spying) {
        this.pause = {
          breaks: [],
          status: {
            name: "",
          },
        };
      }
      this.preMessage = [];

      /* this.error = null;
      this.openInteraction = null;
      this.hideMenu = false;
      this.notifications = [];
      this.dispositions = [];
      this.phonebook = [];
      this.unreadMessageInteractionOpen = null;
      this.loadingForms = {};
      this.loadingFormStatus = {};
      this.serveError = false;
      this.servedForm = null;
      this.codeServer = "";
      this.spying = "";
      this.wrapup = false;
      this.wrapupEnd = null;
      this.unite = false;*/
      this.conferences = [];
      this.inConference = false;
    },
    setWebSocket(ws) {
      this.ws = ws;
    },
    updateContactState(contact, state) {
      if (
        this.contacts.find(contactT => {
          return contactT.username == contact;
        })
      ) {
        this.contacts.find(contactT => {
          return contactT.username == contact;
        }).connected = state;
      }
    },
    updateUnread(guid, unread) {
      const interaction = this.interactions.find(i => i.guid == guid);
      if (interaction) interaction.unread = unread;
    },
    setOpenInteraction(guid) {
      this.openInteraction = guid;
    },
    setFavorite(contact) {
      if (this.favorites.find(fav => fav == contact)) {
        this.favorites = this.favorites.filter(fav => fav != contact);
        this.contacts.find(c => c.username == contact).favorite = 0;
      } else {
        this.favorites.push(contact);
        this.contacts.find(c => c.username == contact).favorite = 1;
      }
    },
    addNotification(notification) {
      if (
        notification.params &&
        notification.params.type &&
        notification.params.type == "dispositions"
      ) {
        window.dispatchEvent(new CustomEvent("getDisposition"));
      }

      notification.date = dateToLocalTime(notification.date).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      );
      this.notifications.push(notification);
    },
    removeNotification(notification) {
      this.notifications = this.notifications.filter(n => {
        return notification.text != n.text || notification.date != n.date;
      });
    },

    removeAllNotification() {
      this.notifications = [];
    },

    async setBreak(breakObject) {
      const res = await useAxios({
        method: "POST",
        url: "/api/inbox/setBreak",
        data: {
          name: breakObject.name,
        },
        headers: {},
      });
      if (!res.success) {
        console.log(res.res);
      }
    },
    async changeAvatarUser(file, avatarObj) {
      const url = `/api/users/id/${
        useAppStore().user.username
      }/avatar?avatar="${JSON.stringify(avatarObj)}"`;

      const res = await useAxios({
        method: "POST",
        url: url,
        data: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      useAppStore().user.avatar =
        JSON.stringify(avatarObj) != "" ? `"${JSON.stringify(avatarObj)}"` : "";

      return res;
    },
    emailMessageError(guid, mail) {
      let interaction = this.interactions.find(i => i.guid == guid);
      if (interaction) {
        for (let i = interaction.messages.length - 1; i >= 0; i--) {
          if (interaction.messages[i].text == mail.message) {
            interaction.messages[i].error = mail.error;
            break;
          }
        }
      }
    },
    setLoadingForms(loadingForms) {
      this.loadingForms = loadingForms;
    },
    setServeError(error) {
      this.serveError = error;
    },
    setServedForm(form) {
      this.servedForm = form;
    },
    setCodeServer(codeServer) {
      this.codeServer = codeServer;
    },
    addLoadingFormStatus(form, status) {
      this.loadingFormStatus[form] = status;
    },
    removeLoadingFormStatus(form) {
      this.loadingFormStatus[form] = null;
    },
    clearNotifications() {
      this.notifications = [];
    },
    updateInteractionData(guid, data) {
      let interaction = this.interactions.find(i => i.guid == guid);
      if (interaction) {
        interaction.data = data;
      }
    },
    setDispositions(dispositions) {
      this.dispositions = dispositions || [];
    },
    setPhonebook(phonebook) {
      this.phonebook = phonebook || [];
    },
    addPhonebook(phonebook) {
      this.phonebook.push(phonebook);
    },
    updatePhonebook(phonebook) {
      this.phonebook = this.phonebook.filter(p => p.id != phonebook.id);
      this.phonebook.push(phonebook);
    },
    deletePhonebook(phonebook) {
      this.phonebook = this.phonebook.filter(p => p.id != phonebook.id);
    },

    needForm(guid, submitted) {
      let interaction = this.interactions.find(i => i.guid == guid);
      if (interaction) {
        interaction.needsForm = !submitted;
      }
    },
    setTransferredInteractions(interactions) {
      if (!interactions) return;

      interactions.forEach(interaction => {
        this.addInteraction(interaction, true);
      });
    },

    //conference

    setConference(conference) {
      let conferenceNumber = conference.conferenceNumber;
      let conferenceObj = this.conferences.find(
        c => c.number == conferenceNumber
      );
      if (conferenceObj) {
        conferenceObj = conference;
      } else {
        this.conferences.push(conference);
      }
      this.inConference = Object.values(this.conferences).some(c =>
        c.members.includes("" + useAppStore().user.phone)
      );

      if (this.inConference)
        usePhoneStore().setClient({
          campaign: "",
          guid: "",
          userNumber: conference.conferenceNumber,
          username: null,
        });
    },
    endConference(conference) {
      this.conferences = this.conferences.filter(
        c => c.conferenceNumber != conference.key
      );
    },
    addConferenceMember(conference) {
      let conferenceObj = this.conferences.find(
        c => c.conferenceNumber == conference.conferenceNumber
      );
      if (conferenceObj) {
        conferenceObj.members = conference.members;
        conferenceObj.micsOn = conference.micsOn;
      } else {
        this.setConference(conference);
      }
    },

    removeConferenceMember(member, conference) {
      let conferenceObj = this.conferences.find(
        c => c.conferenceNumber == conference
      );
      conferenceObj.members = conferenceObj.members.filter(m => m != member);
    },
    muteConferenceMember(object) {
      const { conference, member, isMicOn } = object;
      let conferenceObj = this.conferences.find(
        c => c.conferenceNumber == conference
      );
      conferenceObj.micsOn[member] = isMicOn;

      if (useAppStore().user.phone == member) {
        if (!isMicOn) {
          usePhoneStore().getPhone().mute();
        } else {
          usePhoneStore().getPhone().unMute();
        }
      }
    },
    setTalking(obj) {
      const { conference, talking } = obj;
      let conferenceObj = this.conferences.find(
        c => c.conferenceNumber == conference
      );
      conferenceObj.talking = talking;
    },
    handleNewAgentConnection(data) {
      this.clear();

      const {
        campaigns,
        contacts,
        interactions,
        ringingInteractions,
        transferredInteractions,
        favorites,
        parkedCalls,
        notifications,
        servedForm,
        codeServer,
        loadingForms,
        wrapupEnd,
        unite,
        dispositions,
        phonebook,
        conferences,
        status,
        startBreak,
        serverDatetime,
      } = data;

      this.campaigns = Object.values(campaigns).map(campaign => ({
        ...campaign,
        fullId: `0|${campaign.name}`,
      }));
      this.contacts = Object.values(contacts).map(contact => ({
        ...contact,
        fullId: `0|${[contact.username, useAppStore().user.username]
          .sort()
          .join("|")}`,
      }));
      this.interactions = Object.values(interactions).map(interaction =>
        parseInteraction(interaction)
      );
      this.interactions = this.interactions.concat(
        Object.values(transferredInteractions).map(interaction =>
          parseInteraction(interaction, true)
        )
      );
      this.ringingInteractions = Object.values(ringingInteractions).map(
        interaction => parseRingingInteraction(interaction)
      );
      this.parkedCalls = Object.values(parkedCalls).map(parkCall =>
        parseParkCall(parkCall)
      );
      this.favorites = favorites.split(",").filter(fav => fav);
      this.notifications = notifications;
      this.servedForm = servedForm;
      this.codeServer = codeServer;
      this.loadingForms = loadingForms;
      this.wrapupEnd = wrapupEnd;
      this.unite = unite;
      this.dispositions = dispositions;
      this.phonebook = phonebook.map(p => new Phonebook(p));
      this.conferences = conferences || [];

      useInboxStore().setPauseStatus(status, startBreak, serverDatetime);
    },

    setHoveredMenu(hovered) {
      if (!hovered) {
        setTimeout(() => (this.hoveredMenu = hovered), 200);
      } else {
        this.hoveredMenu = hovered;
        setTimeout(() => (this.hoveredMenu = hovered), 200);
      }
    },
    setPreMessage(guid, preMessage) {
      if (
        this.preMessage.length == 0 ||
        !this.preMessage.some(e => e.guid == guid)
      ) {
        this.preMessage.push({
          guid: guid,
          preMessage: preMessage,
        });
      } else {
        this.preMessage.find(e => e.guid == guid).preMessage = preMessage;
      }
    },

    deletePreMessage(guid) {
      let newPreMesssage = this.preMessage.filter(e => e.guid != guid);
      this.preMessage = newPreMesssage;
    },

    updateInteractionClientName(data) {
      let interaction = this.interactions.find(i => i.guid == data.guid);

      if (interaction) {
        this.interactions = this.interactions.filter(i => i.guid !== data.guid);
        interaction.clientName = data.clientName;
        this.interactions.push(interaction);
      }
    },
    removetempContacts() {
      this.contacts = this.contacts.filter(c => c.tempContact != true);
    },
    addTemplate(template) {
      if (!template.campaign) {
        this.campaigns.forEach(c => {
          c.templates.push(template);
        });
      } else {
        this.campaigns
          .find(c => c.name == template.campaign)
          .templates.push(template);
      }
    },
    removeTemplate(template) {
      if (!template.campaign) {
        this.campaigns.forEach(c => {
          c.templates = c.templates.filter(t => t.name != template.name);
        });
      } else {
        let campaign = this.campaigns.find(c => c.name == template.campaign);

        campaign.templates = campaign.templates.filter(
          t => t.name != template.name
        );
      }
    },

    //phone states
    resetPhoneStates() {
      this.phoneStates = defPhoneStates();
    },

    mute() {
      this.phoneStates.muted = true;
    },

    unMute() {
      this.phoneStates.muted = false;
    },

    hold() {
      this.phoneStates.held = true;
    },

    unHold() {
      this.phoneStates.held = false;
    },

    setSpyingCall(guid) {
      if (!guid) {
        usePhoneStore().getPhone().hangUp();
      }
      this.spyingCall = guid;
    },

    updateDraft(propertie, value, channel, clear) {
      if (clear) this.drafts[channel] = {};
      else {
        if (propertie == "message") {
          this.drafts[channel].text = value.text;
          this.drafts[channel].attachments = value.attachments;
        } else this.drafts[channel][propertie] = value;
      }
    },
    startTransferring(guid) {
      const i = this.interactions.find(i => i.guid == guid);
      if (i) i.transferring = true;
    },
    consultationCall(guid, consultationCall) {
      const i = this.interactions.find(i => i.guid == guid);
      if (i) {
        i.consultationCall = consultationCall;
        if (!i.consultationCall) i.transferring = false;
      }
    },
    changeInteractionCampaign(guid, campaign) {
      const i = this.interactions.find(i => i.guid == guid);
      if (i) {
        i.campaign = campaign;
      }
    },
  },

  undo: {
    disable: true,
  },

  // persist: true,
});

const parseInteraction = (interaction, transferDispositions, spying) => {
  let parsedInteraction = interaction;
  if (
    spying &&
    (interaction.channel == "agent" || interaction.channel == "campaign")
  )
    return;
  parsedInteraction.answerDate = interaction.answerDate
    ? dateToLocalTime(interaction.answerDate).toFormat("yyyy-MM-dd HH:mm:ss")
    : null;
  parsedInteraction.startDate = interaction.startDate
    ? dateToLocalTime(interaction.startDate).toFormat("yyyy-MM-dd HH:mm:ss")
    : null;
  parsedInteraction.finishDate = interaction.finishDate
    ? dateToLocalTime(interaction.finishDate).toFormat("yyyy-MM-dd HH:mm:ss")
    : null;
  parsedInteraction.needsForm = interaction.needsForm || false;

  if (interaction.channel == "agent" || interaction.channel == "campaign") {
    const username = spying ? spying : useAppStore().user.username;
    parsedInteraction.unread = parsedInteraction.internalUnread[username]
      ? parsedInteraction.internalUnread[username]
      : 0;
  }

  parsedInteraction.fullId = interaction.interactionId
    ? `${interaction.interactionId}|${interaction.guid}`
    : interaction.guid;

  if (interaction.lastMessage) {
    parsedInteraction.lastMessage.date = interaction.lastMessage.date
      ? dateToLocalTime(parsedInteraction.lastMessage.date).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )
      : null;
    if (interaction.unread) {
      inbox().playSound(useAppStore().preference.sounds.message);
    }
  } else parsedInteraction.lastMessage = {};

  parsedInteraction.finished = interaction.finished || transferDispositions;

  parsedInteraction.messages = [];

  return parsedInteraction;
};

const parseParkCall = parkCall => {
  let parsedParkCall = parkCall;
  parsedParkCall.guid = parkCall.interaction?.guid;
  parsedParkCall.parked = true;
  parsedParkCall.channel = "telephony";
  parsedParkCall.date = parkCall.date
    ? dateToLocalTime(parkCall.date).toFormat("yyyy-MM-dd HH:mm:ss")
    : null;

  return parsedParkCall;
};

const parseHistoric = historicInteraction => {
  let parsedHistoricInteraction = historicInteraction;
  parsedHistoricInteraction.fullId =
    "id" in historicInteraction
      ? `${historicInteraction.id}|${historicInteraction.guid}`
      : historicInteraction.guid;
  parsedHistoricInteraction.answerDate = historicInteraction.answerDate
    ? dateToLocalTime(historicInteraction.answerDate).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      )
    : null;
  parsedHistoricInteraction.startDate = historicInteraction.startDate
    ? dateToLocalTime(historicInteraction.startDate).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      )
    : null;
  parsedHistoricInteraction.finishDate = historicInteraction.finishDate
    ? dateToLocalTime(historicInteraction.finishDate).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      )
    : null;
  parsedHistoricInteraction.messages.forEach(message => {
    message.date = message.date
      ? dateToLocalTime(message.date).toFormat("yyyy-MM-dd HH:mm:ss")
      : null;
  });

  if (
    historicInteraction.channel == "email" &&
    historicInteraction?.others?.subject
  ) {
    parsedHistoricInteraction.subject = historicInteraction?.others?.subject;
  }

  return parsedHistoricInteraction;
};

const parseRingingInteraction = interaction => {
  let parsedInteraction = interaction;
  parsedInteraction.fullId = interaction.interactionId
    ? `${interaction.interactionId}|${interaction.guid}`
    : interaction.guid;

  parsedInteraction.startDate = interaction.startDate
    ? dateToLocalTime(interaction.startDate).toFormat("yyyy-MM-dd HH:mm:ss")
    : null;
  if (parsedInteraction.lastMessage) {
    parsedInteraction.lastMessage.date = parsedInteraction.lastMessage.date
      ? dateToLocalTime(interaction.lastMessage.date).toFormat(
          "yyyy-MM-dd HH:mm:ss"
        )
      : null;
    if (parsedInteraction.unread) {
      inbox().playSound(useAppStore().preference.sounds.message);
    }
  } else parsedInteraction.lastMessage = {};

  parsedInteraction.messages = [];

  if (parsedInteraction.channel != "telephony")
    inbox().playSound(useAppStore().preference.sounds.interaction);

  return parsedInteraction;
};
