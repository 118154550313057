<template>
  <ReactiveMenu
    @hotkey="openMenu()"
    @open="focusElement()"
    hotkey="alt+b"
    ref="reactiveMenu"
  >
    <template #activator="{ onMenu, attrs }">
      <v-badge
        overlap
        left
        bottom
        offset-x="15"
        offset-y="20"
        color="primary"
        :value="
          useInboxStore.pause.status && useInboxStore.pause.status.name != ''
            ? true
            : false
        "
      >
        <template #badge>
          <Timer
            v-if="
              useInboxStore.pause.status &&
              useInboxStore.pause.status.name != ''
            "
            :start="useInboxStore.pause.status.start"
          ></Timer>
        </template>

        <v-tooltip
          v-if="
            useInboxStore.pause.status && useInboxStore.pause.status.name != ''
          "
          bottom
          transition="slide-y-transition"
        >
          <template #activator="{ on: onTooltip }">
            <v-btn
              :disabled="
                !inTime || useInboxStore.status === 'Already connected'
              "
              @dblclick="setBreak(useInboxStore.pause.status)"
              icon
              v-bind="attrs"
              v-on="{ ...onMenu, ...onTooltip }"
              aria-label="status"
              role="button"
            >
              <v-icon>mdi-play-outline</v-icon>
            </v-btn>
          </template>

          <span>{{ $i18n.t("Status") }}</span>
        </v-tooltip>

        <v-tooltip bottom v-else transition="slide-y-transition">
          <template #activator="{ on: onTooltip }">
            <v-btn
              ref="btnStatus"
              icon
              v-bind="attrs"
              v-on="{ ...onMenu, ...onTooltip }"
              aria-label="status"
              role="button"
            >
              <v-icon>mdi-coffee-outline</v-icon>
            </v-btn>
          </template>

          <span>{{ $i18n.t("Status") }}</span>
        </v-tooltip>
      </v-badge>
    </template>

    <template #content>
      <v-card
        :width="$vuetify.breakpoint.smAndDown ? '' : '370px'"
        elevation="0"
        class="shadow-md container-breaks"
      >
        <v-row no-gutters class="align-center">
          <v-toolbar
            flat
            tile
            class="d-flex justify-center container-toolbar"
            style="position: relative"
            :style="{
              background: colorPerTheme(),
            }"
          >
            <div class="text-center" style="margin: auto">
              <v-btn
                ref="btnSetAsActive"
                rounded
                color="primary"
                v-if="!!useInboxStore.pause.status.name"
                @click="setBreak(useInboxStore.pause.status)"
                role="button"
              >
                <span class="text-caption">
                  {{ $i18n.t("Set as active") }}
                </span>
                <div>
                  <v-icon class="mb-0 ml-2">{{
                    "mdi-play-circle-outline"
                  }}</v-icon>
                </div>
              </v-btn>
            </div>
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              style="position: absolute; right: 1.5rem"
              icon
              @click="openMenu()"
              role="button"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-col
            v-if="useInboxStore.pause.breaks.length && mode == 1"
            cols="12"
          >
            <v-row no-gutters class="text-center justify-center">
              <v-col
                cols="12"
                v-show="useInboxStore.pause.status != null"
                class="pa-3 fill-height"
              >
                <div v-for="(statuses, index) in subBreaks" :key="index">
                  <div
                    no-gutters
                    class="d-flex flex-wrap text-center justify-center px-0"
                  >
                    <v-tooltip
                      bottom
                      v-for="(status, iJ) of statuses"
                      :key="iJ"
                    >
                      <template #activator="{ on }">
                        <v-card
                          ref="status"
                          class="rounded-xl pa-3 break-hover break--card"
                          :class="
                            useInboxStore.pause.status.name &&
                            (status.name == useInboxStore.pause.status.name) !=
                              ''
                              ? 'active-background'
                              : 'background-hover'
                          "
                          flat
                          style=""
                          outlined
                          v-on="on"
                          @keyup.enter="
                            setBreak(status);
                            $refs.reactiveMenu.closeMenu();
                          "
                          @click="setBreak(status)"
                        >
                          <v-icon :color="status.color" large class="mb-2">
                            {{ status.icon }}
                          </v-icon>

                          <div
                            class="text-caption px-1"
                            style="
                              overflow-x: hidden;
                              white-space: nowrap;
                              text-overflow: ellipsis;
                            "
                          >
                            {{
                              defaultBreaks.includes(status.name) ||
                              status.name == "Unavailable"
                                ? $i18n.t(status.name)
                                : status.name
                            }}
                          </div>
                        </v-card>
                      </template>

                      <span>
                        {{
                          defaultBreaks.includes(status.name)
                            ? $i18n.t(status.name)
                            : status.name
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  useInboxStore.pause.status &&
                  useInboxStore.pause.status.name != ''
                "
              >
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="!useInboxStore.pause.breaks.length">
            <BotPlaceholder type="relax" />
          </v-col>
        </v-row>
      </v-card>
    </template>
  </ReactiveMenu>
</template>

<script>
import {
  useAppStore,
  useInboxStore,
  colorPerTheme,
  verifySchedule,
  dateToLocalTime,
  defaultBreaks,
} from "@/utils";
import Timer from "@/components/inbox/interactions/Timer.vue";

import ReactiveMenu from "@/components/base/ReactiveMenu";

export default {
  name: "Breaks",

  components: {
    Timer,

    ReactiveMenu,
  },
  mounted() {
    this.serverDate = dateToLocalTime(useAppStore().serverDate);
    this.checkIsOnTime();
    this.interval = setInterval(async () => {
      this.checkIsOnTime();
    }, 60000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  data() {
    return {
      menu: false,
      mode: 1,
      useInboxStore: useInboxStore(),
      colorPerTheme,
      defaultBreaks,
      carousel: 0,
      showArrows: false,
      unavailble: {
        id: -1,
        name: "Unavailable",
        icon: "mdi-cancel",
        color: "n2p-red",
        campaign: null,
      },
      interval: null,
      inTime: true,
      serverDate: null,
    };
  },

  methods: {
    async setBreak(breakObject) {
      this.serverDate = null;
      if (
        this.useInboxStore.pause &&
        this.useInboxStore.pause.status &&
        this.useInboxStore.pause.status.name == breakObject.name
      ) {
        await this.useInboxStore.setBreak({ name: "" });
        this.$refs.reactiveMenu.closeMenu();
      } else {
        await this.useInboxStore.setBreak(breakObject);
        this.$refs.reactiveMenu.closeMenu();
        useAppStore().updateRankingStatus(breakObject.name);
      }
    },

    openMenu() {
      if (this.$refs.reactiveMenu) {
        this.$refs.reactiveMenu.toggleMenu();
      }
    },
    focusElement() {
      if (
        this.useInboxStore.pause.status &&
        this.useInboxStore.pause.status.name != ""
      ) {
        setTimeout(() => {
          this.$refs.btnSetAsActive?.$el.focus();
        }, 50);
      } else {
        setTimeout(() => {
          this.$refs.status
            ? this.$refs.status[0].$el.focus()
            : this.$refs.btnStatus.$el.focus();
        }, 50);
      }
      this.$emit("close", "status");
    },
    closeMenu() {
      if (this.$refs.reactiveMenu) {
        this.$refs.reactiveMenu.closeMenu();
      }
    },
    async checkIsOnTime() {
      if (!useAppStore().user.scheduled) return;

      let aux = verifySchedule(
        useAppStore().user.scheduled,
        "America/Montevideo"
      );
      if (!aux && this.inTime) {
        await this.useInboxStore.setBreak(this.unavailble);
      }
      this.inTime = aux;
    },
  },

  computed: {
    subBreaks() {
      const subarrays = [];
      const breaks = this.useInboxStore.pause.breaks;
      let sortedBreaks = [];
      const existingNames = sortedBreaks.map(b => b.name);

      breaks.forEach(b => {
        if (!existingNames.includes(b.name)) {
          sortedBreaks.push({
            ...b,
            rank:
              useAppStore().preference.rankingStatus.find(
                cr => cr.name === b.name
              )?.rank || 0,
          });
          existingNames.push(b.name);
        }
      });

      if (!existingNames.includes(this.unavailble.name)) {
        sortedBreaks.unshift(this.unavailble);
      }

      sortedBreaks = sortedBreaks.sort((a, b) =>
        b.rank - a.rank != 0
          ? b.rank - a.rank
          : a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      for (let i = 0; i < sortedBreaks.length; i += 9) {
        const subarray = sortedBreaks.slice(i, i + 9);
        subarrays.push(subarray);
      }

      return subarrays;
    },
  },
};
</script>

<style lang="scss" scoped>
.background-hover {
  cursor: pointer;
  transition: all 200ms ease-in;
}
.active-background {
  cursor: pointer;
  transition: all 200ms ease-in;
}

.theme--dark .break-hover:hover {
  background-color: #414141;
}
.theme--light .break-hover:hover {
  background-color: #eeeeee;
}

.background-set-active:hover {
  background-color: lightgray;
  transition: all 200ms ease-in;
}
.break--card {
  border: none;
  transition: all 200ms ease-in;
  flex: 1 1 30%;
  max-width: 33%;
  max-height: 33%;
}
</style>

<style lang="scss">
.custom-toolbar.v-toolbar {
  height: auto !important;
  .v-toolbar__content {
    height: auto !important;
    padding: 32px;
  }
}
div:has(> .container-breaks) {
  max-height: 550px !important;
}
.container-toolbar .v-toolbar__content {
  position: relative;
  width: 100%;
}

.carousel-breaks {
  .v-window__prev,
  .v-window__next {
    background: transparent !important;
    margin-inline: 0px !important;
  }
  .v-btn--active:hover,
  .theme--light.v-btn--active {
    background: transparent !important;
    &::before {
      opacity: 0 !important;
    }
  }

  .v-btn--active {
    i {
      color: var(--v-primary-base) !important;
      opacity: 1 !important;
    }
  }
}
</style>
