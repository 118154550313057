var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-avatar"},[_c('v-tooltip',_vm._b({attrs:{"disabled":_vm.tooltip.disabled || !_vm.statusObj.icon},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{class:_vm.$props.dashboardAgent
            ? !_vm.statusObj.icon
              ? 'customBadge no-status-icon'
              : _vm.$vuetify.breakpoint.mdAndDown
              ? 'customBadgeMdDown'
              : 'customBadge'
            : !_vm.statusObj.icon
            ? 'navBadge'
            : 'navBadgeStatus',attrs:{"bottom":"","right":"","avatar":"","overlap":"","offset-x":_vm.statusObj.icon ? 4 + _vm.$props.offset : _vm.$props.offset,"offset-y":_vm.statusObj.icon ? 4 + _vm.$props.offset : _vm.$props.offset,"dot":!_vm.statusObj.icon,"color":_vm.statusObj.color},scopedSlots:_vm._u([(_vm.statusObj.icon)?{key:"badge",fn:function(){return [_c('v-avatar',{class:_vm.$props.dashboardAgent ? 'icon-dashboard-color' : null,style:(_vm.$props.dashboardAgent
                ? _vm.$vuetify.breakpoint.mdAndDown
                  ? 'height: 65px !important; min-width: 65px !important; width: 65px !important'
                  : 'height: 32px !important; min-width: 32px !important'
                : null),attrs:{"size":_vm.$props.dashboardAgent ? 34 : 20}},[(!_vm.$props.invert)?_c('img',{ref:"avatarImage",attrs:{"width":_vm.$props.dashboardAgent ? 32 : 20,"height":_vm.$props.dashboardAgent ? 32 : 20,"src":_vm.$props.user.username == _vm.useAppStore().user.username
                  ? _vm.avatarUrl(_vm.$props.user.username, _vm.$props.user.avatar) +
                    '?' +
                    Date.now()
                  : _vm.avatarUrl(_vm.$props.user.username, _vm.$props.user.avatar),"alt":_vm.$props.user.username + ' avatar',"role":"img","aria-label":_vm.$props.user.username + ' avatar'}}):_vm._e(),(_vm.$props.invert)?_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%","height":"100%","background-color":"var(--v-background-base) !important","opacity":"1","position":"absolute","top":"0","left":"0"}},[_c('v-icon',{staticClass:"ma-0",staticStyle:{"position":"absolute","bottom":"0","right":"0"},attrs:{"color":_vm.statusObj.color,"role":"img","aria-label":_vm.statusObj.icon + ' icon'}},[_vm._v(" "+_vm._s(_vm.statusObj.icon)+" ")])],1):_vm._e()])]},proxy:true}:null],null,true)},[_c('v-avatar',_vm._g(_vm._b({staticClass:"ma-0 avatar--user",style:({
            'box-shadow': _vm.$props.dashboardAgent
              ? `0 0 0px 4px ${_vm.statusObj.color} !important`
              : `0 0 0px 2px ${_vm.statusObj.color} !important`,
          }),attrs:{"size":_vm.$props.size - 8,"role":"img","aria-label":_vm.$props.user.username + ' avatar'}},'v-avatar',attrs,false),on),[_c('div',{staticStyle:{"width":"100%","height":"100%","position":"relative"}},[(_vm.statusObj.icon && !_vm.$props.invert)?_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%","height":"100%","opacity":"1","position":"absolute","top":"0","left":"0"},style:({
                'background-color':
                  _vm.$props.dashboardAgent && !_vm.$vuetify.theme.dark
                    ? 'var(--v-background-accent)'
                    : 'var(--v-background-base)',
              })},[_c('v-icon',{staticClass:"ma-0",staticStyle:{"position":"absolute","bottom":"0","right":"0"},attrs:{"color":_vm.statusObj.color,"size":_vm.$props.dashboardAgent ? 50 : null,"role":"img","aria-label":_vm.statusObj.icon + ' icon'}},[_vm._v(" "+_vm._s(_vm.statusObj.icon)+" ")])],1):_vm._e(),(_vm.$props.invert)?_c('img',{ref:"avatarImage",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$props.user.username == _vm.loggedUser.username
                  ? _vm.avatarUrl(_vm.$props.user.username, _vm.$props.user.avatar) +
                    '?' +
                    Date.now()
                  : _vm.avatarUrl(_vm.$props.user.username, _vm.$props.user.avatar),"alt":_vm.$props.user.username + ' avatar',"aria-label":_vm.$props.user.username + ' avatar',"role":"img","width":_vm.$props.size - 8,"height":_vm.$props.size - 8}}):_vm._e(),_c('div',{staticStyle:{"position":"absolute","height":"100%","width":"100%"}},[_c('v-fade-transition',[(!_vm.statusObj.icon)?_c('img',{ref:"avatarImage",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$props.user.username == _vm.loggedUser.username
                      ? _vm.avatarUrl(_vm.$props.user.username, _vm.$props.user.avatar) +
                        '?' +
                        Date.now()
                      : _vm.avatarUrl(_vm.$props.user.username, _vm.$props.user.avatar),"alt":_vm.$props.user.username + ' avatar',"aria-label":_vm.$props.user.username + ' avatar',"role":"img","width":_vm.$props.size - 8,"height":_vm.$props.size - 8}}):_vm._e()])],1)])])],1)]}}])},'v-tooltip',_vm.$props.tooltip,false),[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.te(_vm.statusObj.name) ? _vm.$i18n.t(_vm.statusObj.name) : _vm.statusObj.name)+" "),(_vm.startTime)?[_c('br'),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.startTime)+" ")])]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }